// import { authReducer } from "./authReducer";
import { cartReducer } from "./cartReducer";
import { apiReducer } from "./apiReducer";
import { shopReducer } from "./shopReducer";
import { ordineReducer } from "./ordineReducer";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  ordineReducer,
  apiReducer,
  shopReducer,
  cartReducer,
});

export default rootReducer;
