import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as shopActions from "../redux/actions/shopActions";
import * as cartActions from "../redux/actions/cartActions";

const CodeCheckRoute = ({
  component: Component,
  datiNoleggio,
  caricaDatiGenerali,
  codiceCarrello,
  svuotaCarrello,
  ...rest
}) => {
  const { t, i18n } = useTranslation();

  const checkOk = () => {
    //verifico la validita del codice
    const codNoleggio = rest.computedMatch.params.codiceNoleggio;
    if (codNoleggio === undefined) return false;
    if (codNoleggio.length !== 8) return false;
    //è cambiato??
    if (
      (datiNoleggio.codiceNoleggio === undefined
        ? ""
        : datiNoleggio.codiceNoleggio.toLowerCase()) !==
      codNoleggio.toLowerCase()
    ) {
      if (i18n.language !== undefined) {
        if (codiceCarrello !== undefined) {
          if (codiceCarrello.toLowerCase() !== codNoleggio.toLowerCase()) {
            svuotaCarrello();
          }
        }
        //console.log("Caricamento dati noleggio iniziato at: " + new Date());
        caricaDatiGenerali(
          codNoleggio,
          i18n.language,
          (err) => {
            console.log(
              "errore nel caricamento dati noleggio at: " + new Date()
            );
            return false;
          },
          (data) => {
            //console.log("Caricamento dati noleggio completato at: " + new Date());
          }
        );
      }
    }
    return true;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        checkOk() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

function mapStateToProps(state, ownProps) {
  return {
    datiNoleggio: state.shopReducer.datiNoleggio,
    codiceCarrello: state.cartReducer.codNoleggio,
  };
}

const mapDispatchToProps = {
  caricaDatiGenerali: shopActions.CaricaDatiGenerali,
  setLang: shopActions.SetLingua,
  svuotaCarrello: cartActions.SvuotaCarrello,
};

export default connect(mapStateToProps, mapDispatchToProps)(CodeCheckRoute);
