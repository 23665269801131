import * as actionTypes from "../actions/actionTypes";

const initialState = {
  codNoleggio: "",
  righe: [],
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CART_AGGIUNGI:
      return {
        ...state,
        righe: [...state.righe, action.nuovaRiga],
        codNoleggio: action.codNoleggio,
        nuoveRighe: true,
      };

    case actionTypes.CART_AGGIUNGIOK:
      return {
        ...state,
        nuoveRighe: false,
      };
    case actionTypes.CART_RIMUOVI:
      return {
        ...state,
        righe: state.righe
          .slice(0, action.index)
          .concat(state.righe.slice(action.index + 1, state.righe.length)),
      };
    case actionTypes.CART_SVUOTA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
