import { apiAction } from "./apiActions";
import * as actionTypes from "./actionTypes";

export function SelezionaSettore(id) {
  return {
    type: actionTypes.SHOP_SETSETTORE,
    id,
  };
}

export function SetLingua(lingua) {
  return {
    type: actionTypes.SHOP_SETLINGUA,
    lingua,
  };
}

export function CaricaDatiGenerali(codiceNoleggio, lang, onFailure, onSuccess) {
  return (dispatch, getState) => {
    //Effettuo il caricamento dei dati solo se il codice noleggio è cambiato
    if (codiceNoleggio === getState().shopReducer.datiNoleggio.codiceNoleggio)
      return Promise.resolve();

    dispatch(
      apiAction({
        url: `${
          process.env.REACT_APP_NPURL
        }/api/shop/${codiceNoleggio}/datiNoleggio?Lingua=${
          lang
          //getState().shopReducer.lingua
        }`,
        method: "GET",
        onSuccess: (dati) => {
          onSuccess(dati);
          dispatch({ type: actionTypes.SHOP_DATINOLEGGIO, dati });
        },
        onFailure,
        scope: "SHOP",
      })
    );
  };
}

export function CaricaReparti(idSettore, lang, onFailure, onSuccess) {
  return (dispatch, getState) => {
    if (
      getState().shopReducer.settori.find((x) => x.idNoloshop === idSettore)
        .reparti !== undefined
    )
      return Promise.resolve;

    dispatch(
      apiAction({
        url: `${process.env.REACT_APP_NPURL}/api/shop/${
          getState().shopReducer.datiNoleggio.codiceNoleggio
        }/reparti?idSettore=${
          getState().shopReducer.settori.find((x) => x.idNoloshop === idSettore)
            .id
        }&Lingua=${lang}`,
        method: "GET",
        onSuccess: (dati) => {
          dispatch({ type: actionTypes.SHOP_CARICAREPARTI, dati, idSettore });

          //todo trasformare in globale;
          const dataIniziale = new Date(
            getState().shopReducer.datiNoleggio.dataMinima
          );

          //console.log(dataIniziale);
          // dataIniziale.setDate(dataIniziale.getDate() + 1);

          // console.log(getState());|

          for (let i = 0; i < dati.length; i++) {
            dispatch({
              type: actionTypes.ORDINE_SETREPARTO_GG,
              idReparto: dati[i].idNoloshop,
              giorni: dati[i].giorniProposti,
            });
            dispatch({
              type: actionTypes.ORDINE_SETREPARTO_DATA,
              idReparto: dati[i].idNoloshop,
              data: dataIniziale,
            });
          }

          onSuccess();
        },
        onFailure: onFailure,
        scope: "SHOP",
      })
    );
  };
}

export function SetScrolled(value) {
  return {
    type: actionTypes.SHOP_SETSCROLLED,
    value,
  };
}

export function SetHeaderHeight(height) {
  return {
    type: actionTypes.SHOP_SETHEADERHEIGHT,
    height,
  };
}

// export function SetDataInizio(data) {
//   return {
//     type: actionTypes.SHOP_DATAINIZIO,
//     data,
//   };
// }

// export function SetFrazione(idFrazione) {
//   return {
//     type: actionTypes.SHOP_SETFRAZIONE,
//     idFrazione,
//   };
// }

// export function SetDataInizioReparto(idReparto, data) {
//   return {
//     type: actionTypes.SHOP_DATAINIZIO,
//     idReparto,
//     data,
//   };
// }
