import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Settori from "../../components/Settori";
import Reparti from "../../components/Reparti";
import * as shopActions from "../../redux/actions/shopActions";
import * as ordineActions from "../../redux/actions/ordineActions";
import * as cartActions from "../../redux/actions/cartActions";
import { useTranslation } from "react-i18next";
import { SingleDatePicker } from "react-dates";
import moment from "moment";
import {
  Dimmer,
  Icon,
  Loader,
  Segment,
  Select,
  Transition,
} from "semantic-ui-react";

const Shop = ({
  //caricaDatiGenerali,
  datiNoleggio,
  codiceNoleggio,
  lang,
  setLang,
  datiOrdine,
  headerHeight,
  settori,
  isLoading,
  setSettore,
  caricaReparti,
  setRepartoData,
  setRepartoGiorni,
  setRepartoMisura,
  setRepartoDatiUtilizzatore,
  aggiungiAccessorio,
  rimuoviAccessorio,
  rimuoviAccessori,
  aggiungiRigaCarrello,
  rimuoviRigaCarrello,
  hasRighe,
  ...props
}) => {
  const [dataInizio, setDataInizio] = useState(null);
  const [idFrazione, setIdFrazione] = useState(null);
  const [stagionale, setStagionale] = useState(false);
  const [settoreAttivo, setSettoreAttivo] = useState(-1);
  const [frazioniSettore, setFrazioniSettore] = useState(null);
  const [warningPeriodo, setWarningPeriodo] = useState(false);

  const [dataFocused, setDataFocused] = useState(false);

  const { t, i18n } = useTranslation();

  const repartiRef = useRef(null);

  useEffect(() => {
    if (repartiRef.current !== null && settoreAttivo >= 0) {
      setWarningPeriodo(false);
      //Filtro le frazioni disponibili per il settore selezionato
      const frazDisp = getFrazioniDisponibili();
      const frazTmp = frazDisp.map((f, idx) => ({
        key: f.idNoloShop,
        text: f.testo,
        value: f.idNoloShop,
      }));
      setFrazioniSettore(frazTmp);
      //1 - Se vuota, scelgo la predefinita del settore
      if (idFrazione === null || !hasRighe) {
        if (
          settori.find((x) => x.idNoloshop === settoreAttivo)
            .idFrazionePredefinita > 0
        ) {
          setIdFrazione(
            settori.find((x) => x.idNoloshop === settoreAttivo)
              .idFrazionePredefinita
          );
        } else {
          //Mi assicuro che se non esiste una frazione predefinita, venga scelto il minimo disponibile escludendo 1/2 o altro
          const minIdx = frazDisp.reduce(
            (min, fraz, idx) =>
              (fraz.numGiorni > 0 &&
                fraz.numGiorni < frazDisp[min].numGiorni) ||
              frazDisp[min].numGiorni === 0
                ? idx
                : min,
            0
          );
          setIdFrazione(frazDisp[minIdx].idNoloShop);
          setStagionale(frazDisp[minIdx].stagionale);
        }
      }
      //2 - Se popolata, verifico non sia nella lista delle esclusioni, ed eventualmente imposto la predefinita
      else {
        if (frazDisp.find((x) => x.idNoloShop === idFrazione) === undefined) {
          console.log("WARNING PERIODO");
          setWarningPeriodo(true);
          if (
            settori.find((x) => x.idNoloshop === settoreAttivo)
              .idFrazionePredefinita > 0
          ) {
            setIdFrazione(
              settori.find((x) => x.idNoloshop === settoreAttivo)
                .idFrazionePredefinita
            );
          } else {
            //Mi assicuro che se non esiste una frazione predefinita, venga scelto il minimo disponibile escludendo 1/2 o altro
            const minIdx = frazDisp.reduce(
              (min, fraz, idx) =>
                (fraz.numGiorni > 0 &&
                  fraz.numGiorni < frazDisp[min].numGiorni) ||
                frazDisp[min].numGiorni === 0
                  ? idx
                  : min,
              0
            );
            setIdFrazione(frazDisp[minIdx].idNoloShop);
            setStagionale(frazDisp[minIdx].stagionale);
          }
        }
      }
      window.scrollTo(0, repartiRef.current.offsetTop - 100);
    }
  }, [settoreAttivo]);

  useEffect(() => {
    //console.log(moment(datiNoleggio.dataMinima));
    setDataInizio(moment(datiNoleggio.dataMinima));
  }, [datiNoleggio]);

  // useEffect(() => {
  //   console.log("SETTORI CARICATI");
  // }, [settori]);

  // const dataIniziale = () => {
  //   const data = new Date();
  //   data.setDate(data.getDate() + 1);
  //   return data;
  // };

  useEffect(() => {
    if (isLoading === false && repartiRef.current !== null) {
      window.scrollTo(0, repartiRef.current.offsetTop - 100);
    }
  }, [isLoading]);

  const handleSettoreSelect = (id) => {
    caricaReparti(
      id,
      i18n.language,
      () => {},
      () => {}
    );
    setSettoreAttivo(id);
  };

  // const handleDataCambiata = (idReparto, data) => {
  //   setRepartoData(idReparto, data);
  //   setultimaDataScelta(data);
  // };

  // const handleGiorniCambiati = (idReparto, giorni) => {
  //   setRepartoGiorni(idReparto, giorni);
  // };

  const handleMisuraCambiata = (idReparto, misura) => {
    setRepartoMisura(idReparto, misura);
  };

  const handleDatoUtilizzatore = (idReparto, tipoDato, dato) => {
    setRepartoDatiUtilizzatore(idReparto, tipoDato, dato);
  };

  const handleAggiuntaAccessorio = (idReparto, idAccessorio) => {
    debugger;
    aggiungiAccessorio(idReparto, idAccessorio);
  };

  const handleRimozioneAccessorio = (idReparto, idAccessorio) => {
    rimuoviAccessorio(idReparto, idAccessorio);
  };

  const getNumGiorni = () => {
    return datiNoleggio.frazioniGiornata.find(
      (f) => f.idNoloShop === idFrazione
    ).numGiorni;
  };

  const getDatiReparto = (idReparto) => {
    return settori
      .find((s) => s.idNoloshop === settoreAttivo)
      .reparti.find((r) => r.idNoloshop === idReparto);
  };

  const getPrezzo = (reparto, data, idFrazione) => {
    const listino = reparto.listinoStandard.find(
      (l) =>
        moment(l.dal) <= data &&
        moment(l.al) >= data &&
        l.idFrazioneNoloShop === idFrazione
    );
    if (listino === undefined) return 0;
    return listino.prezzo.toFixed(2);
  };

  const getPrezzoApplicato = (reparto, data, idFrazione) => {
    const listino = reparto.listinoApplicato.find(
      (l) =>
        moment(l.dal) <= data &&
        moment(l.al) >= data &&
        l.idFrazioneNoloShop === idFrazione
    );
    if (listino === undefined) {
      return 0;
    }
    return listino.prezzo.toFixed(2);
  };

  const getPrezzoAccessorio = (accessorio, data, idFrazione) => {
    const listino = accessorio.listinoStandard.find(
      (l) =>
        moment(l.dal) <= data &&
        moment(l.al) >= data &&
        l.idFrazioneNoloShop === idFrazione
    );
    if (listino === undefined) return 0;
    return listino.prezzo.toFixed(2);
  };

  const getPrezzoApplicatoAccessorio = (accessorio, data, idFrazione) => {
    const listino = accessorio.listinoApplicato.find(
      (l) =>
        moment(l.dal) <= data &&
        moment(l.al) >= data &&
        l.idFrazioneNoloShop === idFrazione
    );
    //console.log(listino);
    if (listino === undefined) return 0;
    return listino.prezzo.toFixed(2);
  };

  const handleAggiungiRigaCarrello = (idReparto, datiCliente, misura) => {
    let numGiorni = datiNoleggio.frazioniGiornata.find(
      (f) => f.idNoloShop === idFrazione
    ).numGiorni;
    if (numGiorni === 0) {
      numGiorni = 1;
    }
    const datiReparto = getDatiReparto(idReparto);
    const al = new Date(dataInizio);
    al.setDate(al.getDate() + (numGiorni - 1));

    let accessori = [];
    if (datiOrdine[idReparto].accessori !== undefined) {
      accessori = datiOrdine[idReparto].accessori.map((a) => {
        const acc = datiReparto.accessori.find((acc) => acc.idNoloshop === a);
        return {
          id: acc.id,
          idNoloShop: acc.idNoloshop,
          nomeReparto: acc.titolo,
          prezzo: getPrezzoAccessorio(acc, dataInizio, idFrazione),
          prezzoApplicato: getPrezzoApplicatoAccessorio(
            acc,
            dataInizio,
            idFrazione
          ),
          picture: acc.picture,
          percCaparra: acc.percCaparra,
        };
      });
    }

    aggiungiRigaCarrello(
      {
        datiCliente,
        misura: datiCliente.misura,
        idSettore: settoreAttivo,
        id: datiReparto.id,
        idReparto,
        nomeReparto: datiReparto.titolo,
        nomeSettore: "",
        dal: dataInizio,
        al: al,
        giorni: numGiorni,
        idFrazione: idFrazione,
        picture: datiReparto.picture,
        accessori,
        percCaparra: datiReparto.percCaparra,
        prezzo: getPrezzo(datiReparto, dataInizio, idFrazione),
        prezzoApplicato: getPrezzoApplicato(
          datiReparto,
          dataInizio,
          idFrazione
        ),
      },
      datiNoleggio.codiceNoleggio
    );

    rimuoviAccessori(idReparto);
  };

  const getFrazioniDisponibili = (idSettore) => {
    const frazioni = [];
    const settore = settori.find((s) => s.idNoloshop === settoreAttivo);
    if (settore.frazioniEscluse === null) return datiNoleggio.frazioniGiornata;

    for (let i = 0; i < datiNoleggio.frazioniGiornata.length; i++) {
      if (
        !settore.frazioniEscluse.find(
          (x) => x === datiNoleggio.frazioniGiornata[i].idNoloShop
        )
      ) {
        frazioni.push(datiNoleggio.frazioniGiornata[i]);
      }
    }
    return frazioni;
  };

  // useEffect(() => {
  //   if (ultimaDataScelta === null) return;
  //   for (const rep in datiOrdine) {
  //     if (
  //       new Date(datiOrdine[rep].data).getDate() === dataIniziale().getDate()
  //     ) {
  //       setRepartoData(rep, ultimaDataScelta);
  //     }
  //   }
  // }, [datiOrdine, setRepartoData, ultimaDataScelta]);

  return (
    <>
      <Dimmer active={isLoading && settoreAttivo < 1}>
        <Loader size="massive" active={isLoading}>
          {t("preparazione_negozio")}
        </Loader>
      </Dimmer>
      <Settori
        settori={settori}
        onSelect={handleSettoreSelect}
        codNoleggio={datiNoleggio.codiceNoleggio}
        t={t}
      ></Settori>
      {isLoading && settoreAttivo > 0 && (
        <Segment basic>
          <Dimmer active={isLoading} inverted>
            <Loader active={isLoading} size="huge">
              {t("ricerca_articoli_disponibili")}
            </Loader>
          </Dimmer>
        </Segment>
      )}
      <div ref={repartiRef}> </div>
      {settoreAttivo > 0 && !isLoading && (
        <>
          <div className="reparti-header" style={{ top: headerHeight + "px" }}>
            <div className="reparti-header_titolo">
              {settori.find((x) => x.idNoloshop === settoreAttivo).titolo}
            </div>
            <div className="reparti-header_riga">
              <div>
                <div style={{ fontWeight: "bold" }}>
                  {t("data_inizio_noleggio")}
                </div>
                <div>
                  <SingleDatePicker
                    date={dataInizio}
                    focused={dataFocused}
                    numberOfMonths={1}
                    onDateChange={(date) => {
                      setWarningPeriodo(false);
                      setDataInizio(date);
                      //console.log(date);
                    }}
                    onFocusChange={({ focused }) => {
                      setDataFocused(focused);
                    }}
                    isOutsideRange={(day) =>
                      day.isBefore(moment().add(1, "days"))
                    }
                    readOnly
                    small
                    withPortal
                    showDefaultInputIcon
                    placeholder="DATA INIZIO NOLEGGIO"
                  />
                </div>
              </div>
              <div>
                <div style={{ fontWeight: "bold" }}>{t("durata_noleggio")}</div>
                <div style={{ width: "148px" }}>
                  <Select
                    fluid
                    options={frazioniSettore}
                    onChange={(evt, { value }) => {
                      setWarningPeriodo(false);
                      setIdFrazione(value);
                      setStagionale(
                        datiNoleggio.frazioniGiornata.find(
                          (f) => f.idNoloShop === value
                        ).stagionale
                      );
                    }}
                    value={idFrazione}
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  ></Select>
                </div>
              </div>
              <div>
                <div>&nbsp;</div>
                <div
                  style={{
                    marginLeft: "-18px",
                    paddingTop: "5px",
                    paddingLeft: "0px",
                  }}
                >
                  <Transition
                    visible={warningPeriodo}
                    animation="scale"
                    duration="300"
                  >
                    <Icon name="warning" color="red" size="big"></Icon>
                  </Transition>
                </div>
              </div>
            </div>
          </div>
          {dataInizio !== null && idFrazione !== null && (
            <Reparti
              id="reparti"
              t={t}
              settore={
                settori.find((x) => x.idNoloshop === settoreAttivo).titolo
              }
              reparti={
                settori.find((x) => x.idNoloshop === settoreAttivo).reparti
              }
              stagionale={stagionale}
              datiOrdine={datiOrdine}
              codNoleggio={datiNoleggio.codiceNoleggio}
              dataInizio={dataInizio}
              idFrazione={idFrazione}
              numGiorni={getNumGiorni()}
              //frazioniDisponibili={getFrazioniDisponibili()}
              onMisuraCambiata={handleMisuraCambiata}
              onDatiUtilizzatoreCambiati={handleDatoUtilizzatore}
              onAggiungiAccessorio={handleAggiuntaAccessorio}
              onRimuoviAccessorio={handleRimozioneAccessorio}
              onAggiungiRigaCarrello={handleAggiungiRigaCarrello}
              isLoading={isLoading}
            ></Reparti>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    lang: ownProps.match.params.lang,
    codiceNoleggio: ownProps.match.params.codiceNoleggio,
    isLoading: state.apiReducer.apiCalls > 0,
    datiNoleggio: state.shopReducer.datiNoleggio,
    settori: state.shopReducer.settori,
    settoreAttivo: state.shopReducer.settoreAttivo,
    datiOrdine: state.ordineReducer.repartiStatus,
    headerHeight: state.shopReducer.headerHeight,
    hasRighe: state.cartReducer.righe.length > 0,
  };
}

const mapDispatchToProps = {
  //caricaDatiGenerali: shopActions.CaricaDatiGenerali,
  setSettore: shopActions.SelezionaSettore,
  caricaReparti: shopActions.CaricaReparti,
  setRepartoData: ordineActions.SetRepartoData,
  setRepartoGiorni: ordineActions.SetRepartoGiorni,
  setRepartoMisura: ordineActions.SetRepartoMisura,
  setRepartoDatiUtilizzatore: ordineActions.SetRepartoDatiUtilizzatore,
  aggiungiAccessorio: ordineActions.AggiungiAccessorio,
  rimuoviAccessorio: ordineActions.RimuoviAccessorio,
  rimuoviAccessori: ordineActions.RimuoviAccessori,
  aggiungiRigaCarrello: cartActions.AggiungiRiga,
  rimuoviRigaCarrello: cartActions.RimuoviRiga,
  setLang: shopActions.SetLingua,
};

export default connect(mapStateToProps, mapDispatchToProps)(Shop);
