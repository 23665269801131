import React from "react";
import PropTypes from "prop-types";
import { Card } from "semantic-ui-react";

const Settore = (props) => {
  const picUrl = `https://skinolo.blob.core.windows.net/webimg/${props.codNoleggio}/${props.picture}`;

  return (
    <Card as="a" onClick={() => props.onSelect(props.id)} className="settore">
      <div className="settore_immagine-container">
        <img
          src={picUrl}
          alt={props.titolo}
          className="settore_immagine"
          width="100%"
          height="100%"
        ></img>
      </div>
      <Card.Content>
        <Card.Header>{props.titolo}</Card.Header>
        <Card.Description>{props.sottoTitolo}</Card.Description>
      </Card.Content>
    </Card>
  );
};

Settore.propTypes = {
  id: PropTypes.number.isRequired,
  titolo: PropTypes.string.isRequired,
  sottoTitolo: PropTypes.string.isRequired,
  descrizione: PropTypes.string,
  picture: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

export default Settore;
