import npAxios from "../api/npAxios";
import { API } from "../redux/actions/actionTypes";
import {
  apiCallBegin,
  apiCallError,
  apiCallSuccess,
} from "../redux/actions/apiActions";

export const apiMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type !== API) return;
  //Spread del payload
  const {
    url,
    method,
    data,
    onSuccess,
    onFailure,
    responseType,
    scope,
  } = action.payload;

  //Verifico se i dati vanno come parametri o nel body
  const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

  //Dispatch dell'azione che segna l'avvio di una chiamata Api
  dispatch(apiCallBegin());

  //Eseguo la richiesta vera e propria
  npAxios
    .request({
      url,
      method,
      responseType,
      [dataOrParams]: data,
      npScope: scope,
    })
    .then((resp) => {
      onSuccess(resp.data);
      dispatch(apiCallSuccess());
    })
    .catch((error) => {
      //console.log(error.response.data);
      onFailure(error.response);
      dispatch(apiCallError(error));
    })
    .finally(() => {});
};
