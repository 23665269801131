import { persistStore, persistReducer } from "redux-persist";
import { compose, createStore, applyMiddleware } from "redux";
import { apiMiddleware } from "../middleware/apiMiddleware";
import storage from "redux-persist/lib/storage"; // defaults su localStorage
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import rootReducer from "./reducers";
import initialState from "./reducers/initialState";
import expireReducer from "redux-persist-expire";
import thunk from "redux-thunk";

// Middleware: Redux Persist Config
const persistConfig = {
  // Root?
  key: "root",
  // Storage Method (React Native)
  storage,
  whitelist: ["authReducer", "cartReducer"], //["appReducer", "scuolaReducer", "corsi", "cartReducer"],
  blacklist: ["apiReducer", "appReducer", "shopReducer", "ordineReducer"],
  transforms: [
    expireReducer("authReducer", {
      persistedAtKey: "persistedAt",
      expireSeconds: 60 * 30,
      autoExpire: true,
      expiredState: {
        isLoggedIn: false,
      },
    }),
    expireReducer("cartReducer", {
      persistedAtKey: "persistedAt",
      expireSeconds: 60 * 300,
      autoExpire: true,
      expiredState: { righe: [] },
    }),
  ],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux: Store
const store = createStore(
  persistedReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(thunk, reduxImmutableStateInvariant(), apiMiddleware)
  )
);

// Middleware: Redux Persist Persister
let persistor = persistStore(store);

// Exports
export { store, persistor };
