import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import Backend from "i18next-http-backend";

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  .use(LanguageDetector)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: false,

      detection: {
        order: ["path"],
        lookupFromPathIndex: 0,
      },
      //lng: "it",
      fallbackLng: "en",
      whitelist: ["en", "de", "it", "fr", "ru", "es", "pl", "nl"],

      // ns: ["translation"],
      // defaultNS: "translation",
      // fallbackNS: "translation",

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },

      react: { useSuspense: true },
    },
    () => {
      console.log("Lang: " + i18n.language);
    }
  );

export default i18n;
