//GENERAL API ACTIONS
export const API_CALL_BEGIN = "API_CALL_BEGIN";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const API_CALL_DENIED = "API_CALL_DENIED";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const API_UPDATE_ERROR = "API_UPDATE_ERROR";

export const API = "API";

//AUTH ACTIONS
export const API_LOGIN_REACT = "API_LOGIN_SHOP";
export const API_LOGOUT_REACT = "API_LOGOUT_SHOP";
export const API_LOGIN_MANAGEMENT = "API_LOGIN_MANAGEMENT";
export const API_LOGOUT_MANAGEMENT = "API_LOGOUT_MANAGEMENT";

//SHOP ACTIONS
export const SHOP_SETSETTORE = "SHOP_SETSETTORE";
export const SHOP_DATINOLEGGIO = "SHOP_DATINOLEGGIO";
export const SHOP_CARICAREPARTI = "SHOP_CARICAREPARTI";
export const SHOP_SETLINGUA = "SHOP_SETLINGUA";
export const SHOP_SETSCROLLED = "SHOP_SETSCROLLED";
export const SHOP_SETHEADERHEIGHT = "SHOP_SETHEADERHEIGHT";

//ORDER ACTIONS
export const ORDINE_SETREPARTO_DATA = "ORDER_SETREPARTO_DATA";
export const ORDINE_SETREPARTO_GG = "ORDER_SETREPARTO_GG";
export const ORDINE_SETREPARTO_MISURA = "ORDER_SETREPARTO_MISURA";
export const ORDINE_SETREPARTO_DATIUTILIZZATORE =
  "ORDINE_SETREPARTO_DATIUTILIZZATORE";
export const ORDINE_AGGIUNGIACCESSORIO = "ORDINE_AGGIUNGIACCESSORIO";
export const ORDINE_RIMUOVIACCESSORIO = "ORDINE_RIMUOVIACCESSORIO";
export const ORDINE_RIMUOVIACCESSORI = "ORDINE_RIMUOVIACCESSORI";
export const ORDINE_CLEAR_REPARTO = "ORDINE_CLEAR_REPARTO";
export const ORDINE_CLEAR = "ORDINE_CLEAR";

//CART
export const CART_AGGIUNGI = "CART_AGGIUNGI";
export const CART_AGGIUNGIOK = "CART_AGGIUNGIOK";
export const CART_RIMUOVI = "CART_RIMUOVI";
export const CART_SVUOTA = "CART_SVUOTA";
