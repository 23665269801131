import * as actionTypes from "./actionTypes";
import { apiAction } from "./apiActions";

export function AggiungiRiga(riga, codNoleggio) {
  return {
    type: actionTypes.CART_AGGIUNGI,
    nuovaRiga: riga,
    codNoleggio,
  };
}

export function AggiungiRigaOk() {
  return {
    type: actionTypes.CART_AGGIUNGIOK,
  };
}

export function RimuoviRiga(index) {
  return {
    type: actionTypes.CART_RIMUOVI,
    index,
  };
}

export function SvuotaCarrello() {
  return {
    type: actionTypes.CART_SVUOTA,
  };
}

export function ConfermaCarrello(
  codiceNoleggio,
  cliente,
  datiOrdine,
  onFailure,
  onSuccess
) {
  const data = { cliente, datiOrdine };

  return new apiAction({
    url: `${process.env.REACT_APP_NPURL}/api/shop/${codiceNoleggio}/carrello`,
    method: "POST",
    data,
    onFailure,
    onSuccess,
  });
}
