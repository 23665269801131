export function EffettuaPagamento(skiworkPayload) {
  switch (skiworkPayload.tipoBanca) {
    case 1:
      PagaPhoenix(skiworkPayload);
      break;
    case 2:
      PagaSparkasse(skiworkPayload);
      break;
    case 3:
      PagaSaferPay(skiworkPayload);
      break;
    case 4:
      PagaAxepta(skiworkPayload);
      break;
    case 5:
      PagaXPay(skiworkPayload);
      break;
    case 6:
      PagaConstriv(skiworkPayload);
      break;
    case 7:
      PagaSia(skiworkPayload);
      break;
    default:
      console.log("***ERRORE*** - Sistema di pagamento non supportato");
  }
}

function PagaPhoenix(skiworkPayload) {
  const form = document.createElement("form");
  form.method = "post";
  form.action = skiworkPayload.payload1;

  const obj = JSON.parse(skiworkPayload.payload2);
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      const formElement = document.createElement("input");
      formElement.setAttribute("hidden", true);
      formElement.setAttribute("name", prop);
      formElement.setAttribute("value", obj[prop]);
      form.appendChild(formElement);
    }
  }

  document.body.append(form);
  form.submit();
}

function PagaSparkasse(skiworkPayload) {
  const form = document.createElement("form");
  form.method = "post";
  form.action = skiworkPayload.payload1;

  const formElement = document.createElement("input");
  formElement.setAttribute("hidden", true);
  formElement.setAttribute("type", "hidden");
  formElement.setAttribute("name", "xml");
  formElement.setAttribute("value", skiworkPayload.payload2);
  form.appendChild(formElement);

  const formElement2 = document.createElement("input");
  formElement2.setAttribute("hidden", true);
  formElement2.setAttribute("type", "hidden");
  formElement2.setAttribute("name", "hash");
  formElement2.setAttribute("value", skiworkPayload.payload3);
  form.appendChild(formElement2);

  document.body.append(form);
  form.submit();
}

function PagaAxepta(skiworkPayload) {
  //Invio la richiesta di init Payment
  try {
    const axeptaClient = new window.AxeptaSDKClient(
      skiworkPayload.payload1,
      skiworkPayload.payload2
    );

    debugger;
    if (skiworkPayload.payload5 === "1") {
      axeptaClient.proceedToPayment(skiworkPayload.payload3);
    } else {
      axeptaClient.preparePayment(skiworkPayload.payload3);
    }
  } catch (ex) {
    console.log(ex);
  }
}

function PagaSaferPay(skiworkPayload) {
  window.location.href = JSON.parse(skiworkPayload.payload4).RedirectUrl;
}

function PagaConstriv(skiworkPayload) {
  window.location.href = `${skiworkPayload.payload2}?PaymentID=${skiworkPayload.payload1}`;
}

function PagaSia(skiworkPayload) {
  const form = document.createElement("form");
  form.method = "post";
  form.action = skiworkPayload.payload1;

  const obj = JSON.parse(skiworkPayload.payload2);
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      const formElement = document.createElement("input");
      formElement.setAttribute("hidden", true);
      formElement.setAttribute("name", prop);
      formElement.setAttribute("value", obj[prop]);
      form.appendChild(formElement);
    }
  }

  document.body.append(form);
  debugger;
  form.submit();
}

function PagaXPay(skiworkPayload) {
  const form = document.createElement("form");
  form.method = "post";
  form.action = skiworkPayload.payload1;

  const obj = JSON.parse(skiworkPayload.payload2);
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      const formElement = document.createElement("input");
      formElement.setAttribute("hidden", true);
      formElement.setAttribute("name", prop);
      formElement.setAttribute("value", obj[prop]);
      form.appendChild(formElement);
    }
  }

  document.body.append(form);
  form.submit();
}
