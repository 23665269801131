import React from "react";

const RiepilogoReparto = (props) => {
  const getPrezzoTotale = () => {
    let tot = props.prezzoReparto;
    if (props.accessori.length > 0) {
      tot += parseFloat(
        props.accessori.reduce((prev, cur) => {
          return prev + cur.prezzo;
        }, 0)
      );
    }

    return tot.toFixed(2);
  };

  const getPrezzoTotaleAccessori = () => {
    return props.accessori
      .reduce((prev, curr) => {
        return prev + curr.prezzo;
      }, 0)
      .toFixed(2);
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <div className="riepilogo-reparto">
        <div className="riepilogo-reparto_totale">
          {props.t("totale")}{" "}
          <span className="prezzo">€ {getPrezzoTotale()}</span>
        </div>

        {props.accessori.length > 0 && (
          <>
            <div className="riepilogo-reparto_subtotale">
              {props.t("totale_accessori")}{" "}
              <span className="prezzo">€ {getPrezzoTotaleAccessori()}</span>
            </div>
            {props.accessori.map((a) => (
              <div className="riepilogo-reparto_riga">
                + {a.titolo}{" "}
                <span className="prezzo">€ {a.prezzo.toFixed(2)}</span>
              </div>
            ))}
            <div className="riepilogo-reparto_subtotale">
              {props.t("totale_attrezzatura")}{" "}
              <span className="prezzo">€ {props.prezzoReparto.toFixed(2)}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default RiepilogoReparto;
