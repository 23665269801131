import React from "react";

const Test = () => {
  const content = () => {
    const elements = [];
    for (let i = 0; i < 100; i++) {
      elements.push(<div>Reparto {`${i}`}</div>);
    }
    return elements;
  };
  return (
    <div className="test">
      <div className="test-settori">SETTORI</div>
      <div className="test-header">HEADER</div>
      <div>{content()}</div>
    </div>
  );
};

export default Test;
