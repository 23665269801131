import React from "react";
import { Formik } from "formik";
import { Form, Label, Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import DcsSelect from "../DcsSelect";
import FormikErrorFocus from "formik-error-focus";

const FormTipoBike = (props) => {
  const optionsProfilo = [
    { key: 0, text: "Principiante", value: 0 },
    { key: 1, text: "Intermedio", value: 1 },
    { key: 2, text: "Esperto", value: 2 },
    { key: 3, text: "Agonista", value: 3 },
  ];

  const optionsSesso = [
    { key: 0, text: "Uomo", value: "M" },
    { key: 1, text: "Donna", value: "F" },
  ];

  const optionsPeso = [
    { key: 0, text: "0-17 Kg", value: 0 },
    { key: 1, text: "18-21 Kg", value: 1 },
    { key: 2, text: "22-25 Kg", value: 2 },
    { key: 3, text: "26-30 Kg", value: 3 },
    { key: 4, text: "31-35 Kg", value: 4 },
    { key: 5, text: "36-41 Kg", value: 5 },
    { key: 6, text: "42-48 Kg", value: 6 },
    { key: 7, text: "49-57 Kg", value: 7 },
    { key: 8, text: "58-66 Kg", value: 8 },
    { key: 9, text: "67-78 Kg", value: 9 },
    { key: 10, text: "79-94 Kg", value: 10 },
    { key: 11, text: ">94 Kg", value: 11 },
  ];

  const optionsAltezza = [
    { key: 0, text: "90-95 cm", value: 0 },
    { key: 1, text: "96-100 cm", value: 1 },
    { key: 2, text: "101-105 cm", value: 2 },
    { key: 3, text: "106-110 cm", value: 3 },
    { key: 4, text: "111-115 cm", value: 4 },
    { key: 5, text: "116-120 cm", value: 5 },
    { key: 6, text: "121-125 cm", value: 6 },
    { key: 7, text: "126-130 cm", value: 7 },
    { key: 8, text: "131-135 cm", value: 8 },
    { key: 9, text: "136-140 cm", value: 9 },
    { key: 10, text: "141-145 cm", value: 10 },
    { key: 11, text: "146-150 cm", value: 11 },
    { key: 12, text: "151-155 cm", value: 12 },
    { key: 13, text: "156-160 cm", value: 13 },
    { key: 14, text: "161-165 cm", value: 14 },
    { key: 15, text: "166-170 cm", value: 15 },
    { key: 16, text: "171-175 cm", value: 16 },
    { key: 17, text: "176-180 cm", value: 17 },
    { key: 18, text: "181-185 cm", value: 18 },
    { key: 19, text: "186-190 cm", value: 19 },
    { key: 20, text: "191-195 cm", value: 20 },
    { key: 21, text: "196-200 cm", value: 21 },
    { key: 22, text: ">200 cm", value: 22 },
  ];

  const optionsScarpe = [
    { key: 0, text: "EU 25 / GB:7½ / US:8", value: 0 },
    { key: 1, text: "EU:26 / GB:8½ / US:9", value: 1 },
    { key: 2, text: "EU:27 / GB:9 / US:9.5", value: 2 },
    { key: 3, text: "EU:28 / GB:10 - US:10½", value: 3 },
    { key: 4, text: "EU:29 / GB:11 - US:11½", value: 4 },
    { key: 5, text: "EU:30 / GB:12 - US:12½", value: 5 },
    { key: 6, text: "EU:31 / GB:12½ - US:13", value: 6 },
    { key: 7, text: "EU:32 / GB:13½ - US:13½", value: 7 },
    { key: 8, text: "EU:33 / GB:1 / US:1", value: 8 },
    { key: 9, text: "EU:34 / GB:2 / US:2", value: 9 },
    { key: 10, text: "EU:35 / GB:2½ / US:M3½,F5", value: 10 },
    { key: 11, text: "EU:36 / GB:3½ / US:M4½,F6", value: 11 },
    { key: 12, text: "EU:37 / GB:4 / US:M5,F6½", value: 12 },
    { key: 13, text: "EU:38 / GB:5 / US:M6,F7½", value: 13 },
    { key: 14, text: "EU:39 / GB:6 / US:M7,F8½", value: 14 },
    { key: 15, text: "EU:40 / GB:6½ / US:M7½,F9", value: 15 },
    { key: 16, text: "EU:41 / GB:7 / US:M8,F9½", value: 16 },
    { key: 17, text: "EU:42 / GB:7½ / US:M8½,F10", value: 17 },
    { key: 18, text: "EU:43 / GB:8 / US:M9,F10.5", value: 18 },
    { key: 19, text: "EU:44 / GB:9½ / US:M10½,F12", value: 19 },
    { key: 20, text: "EU:45 / GB:10½ / US:M11½,F13", value: 20 },
    { key: 21, text: "EU:46 / GB:11½ / US:M12½,F14", value: 21 },
    { key: 22, text: "EU:47 / GB:12 / US:M13", value: 22 },
    { key: 23, text: "EU:48 / GB:13 / US:M14", value: 23 },
    { key: 24, text: "EU:49 / GB:13½ / US:M14½", value: 24 },
    { key: 25, text: "EU:50 / GB:14 / US:M15", value: 25 },
  ];

  const validateSchema = Yup.object({
    nome: Yup.string()
      .max(50, props.t("max_50"))
      .required(props.t("valore_richiesto")),
    cognome: Yup.string()
      .max(50, props.t("max_50"))
      .required(props.t("valore_richiesto")),
    // profilo: Yup.string().required("Valore richiesto"),
    // sesso: Yup.string().required("Valore richiesto"),
    peso: Yup.string().required(props.t("valore_richiesto")),
    altezza: Yup.string().required(props.t("valore_richiesto")),
    // scarpe: Yup.string().required("Valore richiesto"),
    misura: props.checkDisponibilita
      ? Yup.string().required(props.t("valore_richiesto"))
      : null,
  });

  return (
    <Formik
      initialValues={{
        nome: "",
        cognome: "",
        peso: "",
        altezza: "",
      }}
      validationSchema={validateSchema}
      onSubmit={(values, { resetForm }) => {
        props.onAggiungiRigaCarrello(values);
        resetForm({ nome: "", cognome: "" });
      }}
      enableReinitialize
      innerRef={props.formRef}
    >
      {({ setFieldValue, ...formik }) => {
        return (
          <Form widths="equal" onSubmit={formik.handleSubmit}>
            {props.checkDisponibilita && (
              <>
                <h3>Misura</h3>
                <div>
                  <Form.Group
                    inline
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    {props.misureDisponibli.map((m) => (
                      <div>
                        <Form.Radio
                          label={m.misura}
                          name="misura"
                          disabled={m.disabled}
                          onChange={() => setFieldValue("misura", m.misura)}
                          value={m.misura}
                          checked={formik.values.misura === m.misura}
                        />
                        {m.disabled && (
                          <div>
                            <div>{props.t("non_disponibile_date_riga1")}</div>
                            <div>{props.t("non_disponibile_date_riga2")}</div>
                            <div>{props.t("non_disponibile_date_riga3")}</div>
                          </div>
                        )}
                      </div>
                    ))}
                  </Form.Group>
                  {formik.values.misura === undefined && formik.dirty && (
                    <Label basic pointing prompt color="red">
                      {props.t("misura_necessaria")}
                    </Label>
                  )}
                </div>
              </>
            )}
            <Divider></Divider>
            <h3>{props.t("dati_utilizzatore")}</h3>
            <Form.Group>
              <Form.Input
                fluid
                label={props.t("nome")}
                placeholder={props.t("nome")}
                id="nome"
                {...formik.getFieldProps("nome")}
                error={formik.touched.nome && formik.errors.nome}
              ></Form.Input>
              <Form.Input
                fluid
                label={props.t("cognome")}
                placeholder={props.t("cognome")}
                id="cognome"
                {...formik.getFieldProps("cognome")}
                error={formik.touched.cognome && formik.errors.cognome}
              ></Form.Input>
            </Form.Group>
            <Form.Group>
              <DcsSelect
                id="peso"
                options={optionsPeso}
                label={props.t("peso")}
                placeholder={props.t("peso")}
                error={formik.touched.peso && formik.errors.peso}
                submitted={props.submitted}
              />
              <DcsSelect
                id="altezza"
                options={optionsAltezza}
                label={props.t("altezza")}
                placeholder={props.t("altezza")}
                error={formik.touched.altezza && formik.errors.altezza}
                submitted={props.submitted}
              />
            </Form.Group>
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={100}
              align={"bottom"}
              focusDelay={150}
              ease={"out-bounce"}
              duration={500}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

FormTipoBike.propTypes = {
  formRef: PropTypes.object.isRequired,
  onAggiungiRigaCarrello: PropTypes.func.isRequired,
  checkDisponibilita: PropTypes.bool.isRequired,
  misureDisponibli: PropTypes.array.isRequired,
};

export default FormTipoBike;
