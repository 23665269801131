import React from "react";
import { connect } from "react-redux";
import { Divider, Segment, Icon, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const Ko = ({ datiNoleggio, lang }) => {
  const history = useHistory();

  return (
    <div className="ko">
      <Segment color="red">
        <h2>TRANSAZIONE NON RIUSCITA</h2>
        <Divider></Divider>
        <p>Oops... Qualcosa non ha funzionato...</p>
        {/* <div onClick={history.push(`/${lang}/${datiNoleggio.codiceNoleggio}`)}> */}
        <Icon name="times circle" size="massive" color="red" />
        {/* </div>  */}
      </Segment>
      <Button
        primary
        onClick={() => {
          history.push(`/${lang}/${datiNoleggio.codiceNoleggio}`);
          window.scrollTo(0, 0);
        }}
      >
        TORNA ALLO SHOP
      </Button>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoading: state.apiReducer.apiCalls > 0,
    datiNoleggio: state.shopReducer.datiNoleggio,
    lang: state.shopReducer.lingua,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Ko);
