import React from "react";
import { Card } from "semantic-ui-react";
import PropTypes from "prop-types";
import Settore from "../Settore";

const Settori = (props) => {
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>{props.t("cosa_vuoi_noleggiare")}</h2>
      <Card.Group centered>
        {props.settori.map((s) => (
          <Settore
            key={s.idNoloshop}
            id={s.idNoloshop}
            titolo={s.titolo}
            sottoTitolo={s.sottoTitolo}
            picture={s.picture}
            codNoleggio={props.codNoleggio}
            onSelect={props.onSelect}
            t={props.t}
          ></Settore>
        ))}
      </Card.Group>
    </div>
  );
};

Settori.propTypes = {
  settori: PropTypes.array.isRequired,
  codNoleggio: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  t: PropTypes.object.isRequired,
};

export default Settori;
