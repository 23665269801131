import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, useField } from "formik";
import { Form, Icon, Button, Select } from "semantic-ui-react";
import * as Yup from "yup";

const FormCliente = (props) => {
  //const formRef = useRef();
  //const [submitted, setSubmitted] = useState(false);

  const validateSchema = Yup.object({
    nome: Yup.string()
      .max(50, "Massimo 50 caratteri!")
      .required("Valore richiesto"),
    cognome: Yup.string()
      .max(50, "Massimo 50 caratteri!")
      .required("Valore richiesto"),
    nazione: Yup.string()
      .max(50, "Massimo 50 caratteri!")
      .required("Valore richiesto"),
    citta: Yup.string()
      .max(50, "Massimo 50 caratteri!")
      .required("Valore richiesto"),
    indirizzo: Yup.string()
      .max(50, "Massimo 50 caratteri!")
      .required("Valore richiesto"),
    telefono: Yup.string()
      .max(50, "Massimo 50 caratteri!")
      .matches(/^\d+$/, "Sono consentiti solo numeri")
      .required("Valore richiesto"),
    email: Yup.string()
      .email("Indirizzo email non valido")
      .max(50, "Massimo 50 caratteri!")
      .required("Valore richiesto"),
    note: Yup.string().max(200, "Massimo 200 caratteri!"),
    condizioni: Yup.boolean().oneOf(
      [true],
      "E' necessario accettare le condizioni"
    ),
    privacy: Yup.boolean().oneOf([true], "E' necessario acconsentire"),
  });

  const getListaNazioni = () => {
    if (props.lingua === "IT")
      return [
        { key: "AF", text: "Afghanistan", value: "AF" },
        { key: "AL", text: "Albania", value: "AL" },
        { key: "DZ", text: "Algeria", value: "DZ" },
        { key: "AD", text: "Andorra", value: "AD" },
        { key: "AO", text: "Angola", value: "AO" },
        { key: "AI", text: "Anguilla", value: "AI" },
        { key: "AG", text: "Antigua e Barbuda", value: "AG" },
        { key: "SA", text: "Arabia Saudita", value: "SA" },
        { key: "AR", text: "Argentina", value: "AR" },
        { key: "AM", text: "Armenia", value: "AM" },
        { key: "AW", text: "Aruba", value: "AW" },
        { key: "AU", text: "Australia", value: "AU" },
        { key: "AT", text: "Austria", value: "AT" },
        { key: "AZ", text: "Azerbaigian", value: "AZ" },
        { key: "BS", text: "Bahamas", value: "BS" },
        { key: "BH", text: "Bahrein", value: "BH" },
        { key: "BD", text: "Bangladesh", value: "BD" },
        { key: "BB", text: "Barbados", value: "BB" },
        { key: "BE", text: "Belgio", value: "BE" },
        { key: "BZ", text: "Belize", value: "BZ" },
        { key: "BJ", text: "Benin", value: "BJ" },
        { key: "BM", text: "Bermuda", value: "BM" },
        { key: "BT", text: "Bhutan", value: "BT" },
        { key: "BY", text: "Bielorussia", value: "BY" },
        { key: "BO", text: "Bolivia", value: "BO" },
        { key: "BA", text: "Bosnia-Erzegovina", value: "BA" },
        { key: "BW", text: "Botswana", value: "BW" },
        { key: "BR", text: "Brasile", value: "BR" },
        { key: "BN", text: "Brunei Darussalam", value: "BN" },
        { key: "BG", text: "Bulgaria", value: "BG" },
        { key: "BF", text: "Burkina Faso", value: "BF" },
        { key: "BI", text: "Burundi", value: "BI" },
        { key: "KH", text: "Cambogia", value: "KH" },
        { key: "CM", text: "Camerun", value: "CM" },
        { key: "CA", text: "Canada", value: "CA" },
        { key: "CV", text: "Capo Verde", value: "CV" },
        { key: "TD", text: "Ciad", value: "TD" },
        { key: "CL", text: "Cile", value: "CL" },
        { key: "CN", text: "Cina", value: "CN" },
        { key: "CY", text: "Cipro", value: "CY" },
        { key: "CO", text: "Colombia", value: "CO" },
        { key: "KM", text: "Comore", value: "KM" },
        { key: "CG", text: "Congo", value: "CG" },
        { key: "KP", text: "Corea del Nord", value: "KP" },
        { key: "KR", text: "Corea del Sud", value: "KR" },
        { key: "CI", text: "Costa dAvorio", value: "CI" },
        { key: "CR", text: "Costa Rica", value: "CR" },
        { key: "HR", text: "Croazia", value: "HR" },
        { key: "CU", text: "Cuba", value: "CU" },
        { key: "CW", text: "Curacao", value: "CW" },
        { key: "DK", text: "Danimarca", value: "DK" },
        { key: "DM", text: "Dominica", value: "DM" },
        { key: "EC", text: "Ecuador", value: "EC" },
        { key: "EG", text: "Egitto", value: "EG" },
        { key: "SV", text: "El Salvador", value: "SV" },
        { key: "AE", text: "Emirati Arabi Uniti", value: "AE" },
        { key: "ER", text: "Eritrea", value: "ER" },
        { key: "EE", text: "Estonia", value: "EE" },
        { key: "SZ", text: "Eswatini", value: "SZ" },
        { key: "ET", text: "Etiopia", value: "ET" },
        { key: "RU", text: "Federazione russa", value: "RU" },
        { key: "FJ", text: "Figi", value: "FJ" },
        { key: "PH", text: "Filippine", value: "PH" },
        { key: "FI", text: "Finlandia", value: "FI" },
        { key: "FR", text: "Francia", value: "FR" },
        { key: "GA", text: "Gabon", value: "GA" },
        { key: "GM", text: "Gambia", value: "GM" },
        { key: "GE", text: "Georgia", value: "GE" },
        { key: "DE", text: "Germania", value: "DE" },
        { key: "GH", text: "Ghana", value: "GH" },
        { key: "JM", text: "Giamaica", value: "JM" },
        { key: "JP", text: "Giappone", value: "JP" },
        { key: "GI", text: "Gibilterra", value: "GI" },
        { key: "DJ", text: "Gibuti", value: "DJ" },
        { key: "JO", text: "Giordania", value: "JO" },
        { key: "GR", text: "Grecia", value: "GR" },
        { key: "GD", text: "Grenada", value: "GD" },
        { key: "GL", text: "Groenlandia", value: "GL" },
        { key: "GT", text: "Guatemala", value: "GT" },
        { key: "GG", text: "Guernsey", value: "GG" },
        { key: "GN", text: "Guinea", value: "GN" },
        { key: "GQ", text: "Guinea equatoriale", value: "GQ" },
        { key: "GW", text: "Guinea-Bissau", value: "GW" },
        { key: "GY", text: "Guyana", value: "GY" },
        { key: "HT", text: "Haiti", value: "HT" },
        { key: "HN", text: "Honduras", value: "HN" },
        { key: "IN", text: "India", value: "IN" },
        { key: "ID", text: "Indonesia", value: "ID" },
        { key: "IR", text: "Iran", value: "IR" },
        { key: "IQ", text: "Iraq", value: "IQ" },
        { key: "IE", text: "Irlanda", value: "IE" },
        { key: "IS", text: "Islanda", value: "IS" },
        { key: "IM", text: "Isola di Man", value: "IM" },
        { key: "KY", text: "Isole Cayman", value: "KY" },
        { key: "CK", text: "Isole Cook (NZ)", value: "CK" },
        { key: "FK", text: "Isole Falkland (Malvine)", value: "FK" },
        { key: "FO", text: "Isole Far Oer", value: "FO" },
        { key: "MH", text: "Isole Marshall", value: "MH" },
        { key: "PN", text: "Isole Pitcairn", value: "PN" },
        { key: "SB", text: "Isole Salomone", value: "SB" },
        { key: "TC", text: "Isole Turks e Caicos", value: "TC" },
        { key: "VG", text: "Isole Vergini britanniche", value: "VG" },
        { key: "IL", text: "Israele", value: "IL" },
        { key: "IT", text: "Italia", value: "IT" },
        { key: "JE", text: "Jersey", value: "JE" },
        { key: "KZ", text: "Kazakhstan", value: "KZ" },
        { key: "KE", text: "Kenya", value: "KE" },
        { key: "KG", text: "Kirghizistan", value: "KG" },
        { key: "KI", text: "Kiribati", value: "KI" },
        { key: "KW", text: "Kuwait", value: "KW" },
        { key: "LA", text: "Laos", value: "LA" },
        { key: "LS", text: "Lesotho", value: "LS" },
        { key: "LV", text: "Lettonia", value: "LV" },
        { key: "LB", text: "Libano", value: "LB" },
        { key: "LR", text: "Liberia", value: "LR" },
        { key: "LY", text: "Libia", value: "LY" },
        { key: "LI", text: "Liechtenstein", value: "LI" },
        { key: "LT", text: "Lituania", value: "LT" },
        { key: "LU", text: "Lussemburgo", value: "LU" },
        { key: "MK", text: "Macedonia del Nord", value: "MK" },
        { key: "MG", text: "Madagascar", value: "MG" },
        { key: "MW", text: "Malawi", value: "MW" },
        { key: "MY", text: "Malaysia", value: "MY" },
        { key: "MV", text: "Maldive", value: "MV" },
        { key: "ML", text: "Mali", value: "ML" },
        { key: "MT", text: "Malta", value: "MT" },
        { key: "MA", text: "Marocco", value: "MA" },
        { key: "MR", text: "Mauritania", value: "MR" },
        { key: "MU", text: "Maurizio", value: "MU" },
        { key: "MX", text: "Messico", value: "MX" },
        { key: "MD", text: "Moldova", value: "MD" },
        { key: "MC", text: "Monaco", value: "MC" },
        { key: "MN", text: "Mongolia", value: "MN" },
        { key: "ME", text: "Montenegro", value: "ME" },
        { key: "MS", text: "Montserrat", value: "MS" },
        { key: "MZ", text: "Mozambico", value: "MZ" },
        { key: "MM", text: "Myanmar/Birmania", value: "MM" },
        { key: "NA", text: "Namibia", value: "NA" },
        { key: "NR", text: "Nauru", value: "NR" },
        { key: "NP", text: "Nepal", value: "NP" },
        { key: "NI", text: "Nicaragua", value: "NI" },
        { key: "NE", text: "Niger", value: "NE" },
        { key: "NG", text: "Nigeria", value: "NG" },
        { key: "NO", text: "Norvegia", value: "NO" },
        { key: "NC", text: "Nuova Caledonia", value: "NC" },
        { key: "NZ", text: "Nuova Zelanda", value: "NZ" },
        { key: "OM", text: "Oman", value: "OM" },
        { key: "NL", text: "Paesi Bassi", value: "NL" },
        { key: "PK", text: "Pakistan", value: "PK" },
        { key: "PW", text: "Palau", value: "PW" },
        { key: "PS", text: "Palestina", value: "PS" },
        { key: "PA", text: "Panama", value: "PA" },
        { key: "PG", text: "Papua Nuova Guinea", value: "PG" },
        { key: "PY", text: "Paraguay", value: "PY" },
        { key: "PE", text: "Perù", value: "PE" },
        { key: "PF", text: "Polinesia francese", value: "PF" },
        { key: "PL", text: "Polonia", value: "PL" },
        { key: "PT", text: "Portogallo", value: "PT" },
        { key: "QA", text: "Qatar", value: "QA" },
        { key: "UK", text: "Regno Unito", value: "UK" },
        { key: "CZ", text: "Repubblica ceca", value: "CZ" },
        { key: "CF", text: "Repubblica Centrafricana", value: "CF" },
        { key: "CD", text: "Repubblica Democratica del Congo", value: "CD" },
        { key: "DO", text: "Repubblica Dominicana", value: "DO" },
        { key: "RO", text: "Romania", value: "RO" },
        { key: "RW", text: "Ruanda", value: "RW" },
        { key: "EH", text: "Sahara occidentale", value: "EH" },
        { key: "KN", text: "Saint Kitts e Nevis", value: "KN" },
        { key: "PM", text: "Saint Pierre e Miquelon", value: "PM" },
        { key: "VC", text: "Saint Vincent e Grenadine", value: "VC" },
        { key: "BL", text: "Saint-Barth�lemy", value: "BL" },
        { key: "MF", text: "Saint-Martin (FR)", value: "MF" },
        { key: "WS", text: "Samoa", value: "WS" },
        { key: "SM", text: "San Marino", value: "SM" },
        { key: "SH", text: "Sant'Elena", value: "SH" },
        { key: "LC", text: "Santa Lucia", value: "LC" },
        { key: "ST", text: "Sao Tomo e Principe", value: "ST" },
        { key: "SN", text: "Senegal", value: "SN" },
        { key: "RS", text: "Serbia", value: "RS" },
        { key: "SC", text: "Seychelles", value: "SC" },
        { key: "SL", text: "Sierra Leone", value: "SL" },
        { key: "SG", text: "Singapore", value: "SG" },
        { key: "SX", text: "Sint Maarten (NL)", value: "SX" },
        { key: "SY", text: "Siria", value: "SY" },
        { key: "SK", text: "Slovacchia", value: "SK" },
        { key: "SI", text: "Slovenia", value: "SI" },
        { key: "SO", text: "Somalia", value: "SO" },
        { key: "ES", text: "Spagna", value: "ES" },
        { key: "LK", text: "Sri Lanka", value: "LK" },
        { key: "FM", text: "Stati Federati di Micronesia", value: "FM" },
        { key: "US", text: "Stati Uniti d'America", value: "US" },
        { key: "VA", text: "Stato della Città del Vaticano", value: "VA" },
        { key: "SS", text: "Sud Sudan", value: "SS" },
        { key: "ZA", text: "Sudafrica", value: "ZA" },
        { key: "SD", text: "Sudan", value: "SD" },
        { key: "SR", text: "Suriname", value: "SR" },
        { key: "SE", text: "Svezia", value: "SE" },
        { key: "CH", text: "Svizzera", value: "CH" },
        { key: "TJ", text: "Tagikistan", value: "TJ" },
        { key: "TW", text: "Taiwan", value: "TW" },
        { key: "TZ", text: "Tanzania", value: "TZ" },
        { key: "TH", text: "Thailandia", value: "TH" },
        { key: "TL", text: "Timor Leste", value: "TL" },
        { key: "TG", text: "Togo", value: "TG" },
        { key: "TO", text: "Tonga", value: "TO" },
        { key: "TT", text: "Trinidad e Tobago", value: "TT" },
        { key: "TN", text: "Tunisia", value: "TN" },
        { key: "TR", text: "Turchia", value: "TR" },
        { key: "TM", text: "Turkmenistan", value: "TM" },
        { key: "TV", text: "Tuvalu", value: "TV" },
        { key: "UA", text: "Ucraina", value: "UA" },
        { key: "UG", text: "Uganda", value: "UG" },
        { key: "HU", text: "Ungheria", value: "HU" },
        { key: "UY", text: "Uruguay", value: "UY" },
        { key: "UZ", text: "Uzbekistan", value: "UZ" },
        { key: "VU", text: "Vanuatu", value: "VU" },
        { key: "VE", text: "Venezuela", value: "VE" },
        { key: "VN", text: "Vietnam", value: "VN" },
        { key: "WF", text: "Wallis e Futuna", value: "WF" },
        { key: "YE", text: "Yemen", value: "YE" },
        { key: "ZM", text: "Zambia", value: "ZM" },
        { key: "ZW", text: "Zimbabwe", value: "ZW" },
      ];
    else
      return [
        { key: "AF", text: "Afghanistan", value: "AF" },
        { key: "AL", text: "Albania", value: "AL" },
        { key: "DZ", text: "Algeria", value: "DZ" },
        { key: "AD", text: "Andorra", value: "AD" },
        { key: "AO", text: "Angola", value: "AO" },
        { key: "AI", text: "Anguilla", value: "AI" },
        { key: "AG", text: "Antigua and Barbuda", value: "AG" },
        { key: "AR", text: "Argentina", value: "AR" },
        { key: "AM", text: "Armenia", value: "AM" },
        { key: "AW", text: "Aruba", value: "AW" },
        { key: "AU", text: "Australia", value: "AU" },
        { key: "AT", text: "Austria", value: "AT" },
        { key: "AZ", text: "Azerbaijan", value: "AZ" },
        { key: "BS", text: "Bahamas", value: "BS" },
        { key: "BH", text: "Bahrain", value: "BH" },
        { key: "BD", text: "Bangladesh", value: "BD" },
        { key: "BB", text: "Barbados", value: "BB" },
        { key: "BY", text: "Belarus", value: "BY" },
        { key: "BE", text: "Belgium", value: "BE" },
        { key: "BZ", text: "Belize", value: "BZ" },
        { key: "BJ", text: "Benin", value: "BJ" },
        { key: "BM", text: "Bermuda", value: "BM" },
        { key: "BT", text: "Bhutan", value: "BT" },
        { key: "BO", text: "Bolivia", value: "BO" },
        { key: "BA", text: "Bosnia and Herzegovina", value: "BA" },
        { key: "BW", text: "Botswana", value: "BW" },
        { key: "BR", text: "Brazil", value: "BR" },
        { key: "VG", text: "British Virgin Islands", value: "VG" },
        { key: "BN", text: "Brunei Darussalam", value: "BN" },
        { key: "BG", text: "Bulgaria", value: "BG" },
        { key: "BF", text: "Burkina Faso", value: "BF" },
        { key: "BI", text: "Burundi", value: "BI" },
        { key: "KH", text: "Cambodia", value: "KH" },
        { key: "CM", text: "Cameroon", value: "CM" },
        { key: "CA", text: "Canada", value: "CA" },
        { key: "CV", text: "Cape Verde", value: "CV" },
        { key: "KY", text: "Cayman Islands", value: "KY" },
        { key: "CF", text: "Central African Republic", value: "CF" },
        { key: "TD", text: "Chad", value: "TD" },
        { key: "CL", text: "Chile", value: "CL" },
        { key: "CN", text: "China", value: "CN" },
        { key: "CO", text: "Colombia", value: "CO" },
        { key: "KM", text: "Comoros", value: "KM" },
        { key: "CG", text: "Congo", value: "CG" },
        { key: "CK", text: "Cook Islands (NZ)", value: "CK" },
        { key: "CR", text: "Costa Rica", value: "CR" },
        { key: "HR", text: "Croatia", value: "HR" },
        { key: "CU", text: "Cuba", value: "CU" },
        { key: "CW", text: "Curacao", value: "CW" },
        { key: "CY", text: "Cyprus", value: "CY" },
        { key: "CZ", text: "Czech Republic", value: "CZ" },
        { key: "CI", text: "Cote d'Ivoire", value: "CI" },
        { key: "CD", text: "Democratic Republic of the Congo", value: "CD" },
        { key: "DK", text: "Denmark", value: "DK" },
        { key: "DJ", text: "Djibouti", value: "DJ" },
        { key: "DM", text: "Dominica", value: "DM" },
        { key: "DO", text: "Dominican Republic", value: "DO" },
        { key: "EC", text: "Ecuador", value: "EC" },
        { key: "EG", text: "Egypt", value: "EG" },
        { key: "SV", text: "El Salvador", value: "SV" },
        { key: "GQ", text: "Equatorial Guinea", value: "GQ" },
        { key: "ER", text: "Eritrea", value: "ER" },
        { key: "EE", text: "Estonia", value: "EE" },
        { key: "SZ", text: "Eswatini", value: "SZ" },
        { key: "ET", text: "Ethiopia", value: "ET" },
        { key: "FK", text: "Falkland Islands (Malvinas)", value: "FK" },
        { key: "FO", text: "Faroe Islands", value: "FO" },
        { key: "FM", text: "Federated States of Micronesia", value: "FM" },
        { key: "FJ", text: "Fiji", value: "FJ" },
        { key: "FI", text: "Finland", value: "FI" },
        { key: "FR", text: "France", value: "FR" },
        { key: "PF", text: "French Polynesia", value: "PF" },
        { key: "TF", text: "French Southern Territories", value: "TF" },
        { key: "GA", text: "Gabon", value: "GA" },
        { key: "GM", text: "Gambia", value: "GM" },
        { key: "GE", text: "Georgia", value: "GE" },
        { key: "DE", text: "Germany", value: "DE" },
        { key: "GH", text: "Ghana", value: "GH" },
        { key: "GI", text: "Gibraltar", value: "GI" },
        { key: "GR", text: "Greece", value: "GR" },
        { key: "GL", text: "Greenland", value: "GL" },
        { key: "GD", text: "Grenada", value: "GD" },
        { key: "GT", text: "Guatemala", value: "GT" },
        { key: "GG", text: "Guernsey", value: "GG" },
        { key: "GN", text: "Guinea", value: "GN" },
        { key: "GW", text: "Guinea-Bissau", value: "GW" },
        { key: "GY", text: "Guyana", value: "GY" },
        { key: "HT", text: "Haiti", value: "HT" },
        { key: "HN", text: "Honduras", value: "HN" },
        { key: "HU", text: "Hungary", value: "HU" },
        { key: "IS", text: "Iceland", value: "IS" },
        { key: "IN", text: "India", value: "IN" },
        { key: "ID", text: "Indonesia", value: "ID" },
        { key: "IR", text: "Iran", value: "IR" },
        { key: "IQ", text: "Iraq", value: "IQ" },
        { key: "IE", text: "Ireland", value: "IE" },
        { key: "IM", text: "Isle of Man", value: "IM" },
        { key: "IL", text: "Israel", value: "IL" },
        { key: "IT", text: "Italy", value: "IT" },
        { key: "JM", text: "Jamaica", value: "JM" },
        { key: "JP", text: "Japan", value: "JP" },
        { key: "JE", text: "Jersey", value: "JE" },
        { key: "JO", text: "Jordan", value: "JO" },
        { key: "KZ", text: "Kazakhstan", value: "KZ" },
        { key: "KE", text: "Kenya", value: "KE" },
        { key: "KI", text: "Kiribati", value: "KI" },
        { key: "KW", text: "Kuwait", value: "KW" },
        { key: "KG", text: "Kyrgyzstan", value: "KG" },
        { key: "LA", text: "Laos", value: "LA" },
        { key: "LV", text: "Latvia", value: "LV" },
        { key: "LB", text: "Lebanon", value: "LB" },
        { key: "LS", text: "Lesotho", value: "LS" },
        { key: "LR", text: "Liberia", value: "LR" },
        { key: "LY", text: "Libya", value: "LY" },
        { key: "LI", text: "Liechtenstein", value: "LI" },
        { key: "LT", text: "Lithuania", value: "LT" },
        { key: "LU", text: "Luxembourg", value: "LU" },
        { key: "MG", text: "Madagascar", value: "MG" },
        { key: "MW", text: "Malawi", value: "MW" },
        { key: "MY", text: "Malaysia", value: "MY" },
        { key: "MV", text: "Maldives", value: "MV" },
        { key: "ML", text: "Mali", value: "ML" },
        { key: "MT", text: "Malta", value: "MT" },
        { key: "MH", text: "Marshall Islands", value: "MH" },
        { key: "MR", text: "Mauritania", value: "MR" },
        { key: "MU", text: "Mauritius", value: "MU" },
        { key: "MX", text: "Mexico", value: "MX" },
        { key: "MD", text: "Moldova", value: "MD" },
        { key: "MC", text: "Monaco", value: "MC" },
        { key: "MN", text: "Mongolia", value: "MN" },
        { key: "ME", text: "Montenegro", value: "ME" },
        { key: "MS", text: "Montserrat", value: "MS" },
        { key: "MA", text: "Morocco", value: "MA" },
        { key: "MZ", text: "Mozambique", value: "MZ" },
        { key: "MM", text: "Myanmar/Burma", value: "MM" },
        { key: "NA", text: "Namibia", value: "NA" },
        { key: "NR", text: "Nauru", value: "NR" },
        { key: "NP", text: "Nepal", value: "NP" },
        { key: "NL", text: "Netherlands", value: "NL" },
        { key: "NC", text: "New Caledonia", value: "NC" },
        { key: "NZ", text: "New Zealand", value: "NZ" },
        { key: "NI", text: "Nicaragua", value: "NI" },
        { key: "NE", text: "Niger", value: "NE" },
        { key: "NG", text: "Nigeria", value: "NG" },
        { key: "KP", text: "North Korea", value: "KP" },
        { key: "MK", text: "North Macedonia", value: "MK" },
        { key: "NO", text: "Norway", value: "NO" },
        { key: "OM", text: "Oman", value: "OM" },
        { key: "PK", text: "Pakistan", value: "PK" },
        { key: "PW", text: "Palau", value: "PW" },
        { key: "PS", text: "Palestine", value: "PS" },
        { key: "PA", text: "Panama", value: "PA" },
        { key: "PG", text: "Papua New Guinea", value: "PG" },
        { key: "PY", text: "Paraguay", value: "PY" },
        { key: "PE", text: "Peru", value: "PE" },
        { key: "PH", text: "Philippines", value: "PH" },
        { key: "PN", text: "Pitcairn", value: "PN" },
        { key: "PL", text: "Poland", value: "PL" },
        { key: "PT", text: "Portugal", value: "PT" },
        { key: "QA", text: "Qatar", value: "QA" },
        { key: "RO", text: "Romania", value: "RO" },
        { key: "RU", text: "Russian Federation", value: "RU" },
        { key: "RW", text: "Rwanda", value: "RW" },
        { key: "BL", text: "Saint Barthelemy", value: "BL" },
        { key: "SH", text: "Saint Helena", value: "SH" },
        { key: "KN", text: "Saint Kitts and Nevis", value: "KN" },
        { key: "LC", text: "Saint Lucia", value: "LC" },
        { key: "MF", text: "Saint Martin (FR)", value: "MF" },
        { key: "PM", text: "Saint Pierre and Miquelon", value: "PM" },
        { key: "VC", text: "Saint Vincent and the Grenadines", value: "VC" },
        { key: "WS", text: "Samoa", value: "WS" },
        { key: "SM", text: "San Marino", value: "SM" },
        { key: "ST", text: "Sao Tome and Principe", value: "ST" },
        { key: "SA", text: "Saudi Arabia", value: "SA" },
        { key: "SN", text: "Senegal", value: "SN" },
        { key: "RS", text: "Serbia", value: "RS" },
        { key: "SC", text: "Seychelles", value: "SC" },
        { key: "SL", text: "Sierra Leone", value: "SL" },
        { key: "SG", text: "Singapore", value: "SG" },
        { key: "SK", text: "Slovakia", value: "SK" },
        { key: "SI", text: "Slovenia", value: "SI" },
        { key: "SB", text: "Solomon Islands", value: "SB" },
        { key: "SO", text: "Somalia", value: "SO" },
        { key: "ZA", text: "South Africa", value: "ZA" },
        { key: "KR", text: "South Korea", value: "KR" },
        { key: "SS", text: "South Sudan", value: "SS" },
        { key: "ES", text: "Spain", value: "ES" },
        { key: "LK", text: "Sri Lanka", value: "LK" },
        { key: "SX", text: "St Maarten (NL)", value: "SX" },
        { key: "SD", text: "Sudan", value: "SD" },
        { key: "SR", text: "Suriname", value: "SR" },
        { key: "SE", text: "Sweden", value: "SE" },
        { key: "CH", text: "Switzerland", value: "CH" },
        { key: "SY", text: "Syria", value: "SY" },
        { key: "TW", text: "Taiwan", value: "TW" },
        { key: "TJ", text: "Tajikistan", value: "TJ" },
        { key: "TZ", text: "Tanzania", value: "TZ" },
        { key: "TH", text: "Thailand", value: "TH" },
        { key: "TL", text: "Timor Leste", value: "TL" },
        { key: "TG", text: "Togo", value: "TG" },
        { key: "TO", text: "Tonga", value: "TO" },
        { key: "TT", text: "Trinidad and Tobago", value: "TT" },
        { key: "TN", text: "Tunisia", value: "TN" },
        { key: "TR", text: "Turkey", value: "TR" },
        { key: "TM", text: "Turkmenistan", value: "TM" },
        { key: "TC", text: "Turks and Caicos Islands", value: "TC" },
        { key: "TV", text: "Tuvalu", value: "TV" },
        { key: "UG", text: "Uganda", value: "UG" },
        { key: "UA", text: "Ukraine", value: "UA" },
        { key: "AE", text: "United Arab Emirates", value: "AE" },
        { key: "UK", text: "United Kingdom", value: "UK" },
        { key: "US", text: "United States of America", value: "US" },
        { key: "UY", text: "Uruguay", value: "UY" },
        { key: "UZ", text: "Uzbekistan", value: "UZ" },
        { key: "VU", text: "Vanuatu", value: "VU" },
        { key: "VA", text: "Vatican City State", value: "VA" },
        { key: "VE", text: "Venezuela", value: "VE" },
        { key: "VN", text: "Vietnam", value: "VN" },
        { key: "WF", text: "Wallis and Futuna Islands", value: "WF" },
        { key: "EH", text: "Western Sahara", value: "EH" },
        { key: "YE", text: "Yemen", value: "YE" },
        { key: "ZM", text: "Zambia", value: "ZM" },
        { key: "ZW", text: "Zimbabwe", value: "ZW" },
      ];
  };

  const DcsSelect = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props.id);
    return (
      <Form.Field
        control={Select}
        label={label}
        search
        error={
          meta.error === ""
            ? null
            : meta.error !== "" && meta.touched
            ? meta.error
            : null
        }
        onChange={(e, v) => helpers.setValue(v.value)}
        value={field.value !== undefined ? field.value : null}
        {...props}
      />
    );
  };

  let initialValues = {};
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    initialValues = {
      nome: "Juri",
      cognome: "Capovilla",
      nazione: "IT",
      citta: "Predazzo",
      indirizzo: "Via delle rose, 26",
      telefono: "0462502388",
      email: "juricapovilla@gmail.com",
      note: "test",
      condizioni: "false",
      privacy: "false",
    };
  } else {
    initialValues = {
      nome: "",
      cognome: "",
      nazione: "",
      citta: "",
      indirizzo: "",
      telefono: "",
      email: "",
      note: "",
      condizioni: "false",
      privacy: "false",
    };
  }

  return (
    <div className="form-cliente">
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={(values) => {
          props.onSubmit(values);
        }}
        enableReinitialize
        //innerRef={formRef}
      >
        {({ ...formik }) => {
          return (
            <Form
              widths="equal"
              onSubmit={formik.handleSubmit}
              id="form-cliente"
            >
              <Form.Group>
                <Form.Input
                  fluid
                  label={props.t("nome")}
                  placeholder={props.t("nome")}
                  id="nome"
                  {...formik.getFieldProps("nome")}
                  error={formik.touched.nome && formik.errors.nome}
                ></Form.Input>
                <Form.Input
                  fluid
                  label={props.t("cognome")}
                  placeholder={props.t("cognome")}
                  id="cognome"
                  {...formik.getFieldProps("cognome")}
                  error={formik.touched.cognome && formik.errors.cognome}
                ></Form.Input>
              </Form.Group>
              <DcsSelect
                id="nazione"
                options={getListaNazioni()}
                label={props.t("nazione")}
                placeholder={props.t("nazione")}
              />
              <Form.Input
                fluid
                label={props.t("citta")}
                placeholder={props.t("citta")}
                id="citta"
                {...formik.getFieldProps("citta")}
                error={formik.touched.citta && formik.errors.citta}
              ></Form.Input>
              <Form.Input
                fluid
                label={props.t("indirizzo")}
                placeholder={props.t("indirizzo")}
                id="indirizzo"
                {...formik.getFieldProps("indirizzo")}
                error={formik.touched.indirizzo && formik.errors.indirizzo}
              ></Form.Input>
              <Form.Input
                fluid
                label={props.t("Cellulare")}
                placeholder={props.t("cellulare")}
                id="telefono"
                {...formik.getFieldProps("telefono")}
                error={formik.touched.telefono && formik.errors.telefono}
              ></Form.Input>
              <Form.Input
                fluid
                label={props.t("email")}
                placeholder={props.t("email")}
                id="email"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
              ></Form.Input>
              <Form.TextArea
                label={props.t("note")}
                placeholder={props.t("note")}
                id="note"
                {...formik.getFieldProps("note")}
                error={formik.touched.note && formik.errors.note}
              ></Form.TextArea>
              <h3>{props.t("condizioni_vendita_privacy")}</h3>
              <div
                style={{ cursor: "pointer", marginBottom: "14px" }}
                onClick={() => {
                  props.onShowCondizioni();
                  // setShowCondizioni(true);
                }}
              >
                <a>
                  {props.t("condizioni_vendita")} <Icon name="external"></Icon>
                </a>
              </div>
              <Form.Checkbox
                label={props.t("accetto_condizioni_vendita")}
                id="condizioni"
                {...formik.getFieldProps("condizioni")}
                error={formik.touched.condizioni && formik.errors.condizioni}
              />
              <div
                style={{ cursor: "pointer", marginBottom: "14px" }}
                onClick={() => {
                  props.onShowPrivacy();
                  // setShowCondizioni(true);
                }}
              >
                <a>
                  {props.t("informativa_privacy")} <Icon name="external"></Icon>
                </a>
              </div>
              <Form.Checkbox
                label={props.t("accetto_privacy")}
                id="privacy"
                {...formik.getFieldProps("privacy")}
                error={formik.touched.privacy && formik.errors.privacy}
              />

              {props.children}

              <div
                style={{
                  textAlign: "center",
                  marginTop: "1.5rem",
                }}
              >
                <Button primary type="submit">
                  <Icon name="credit card" />
                  {props.t("vai_al_pagamento")}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

FormCliente.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onShowCondizioni: PropTypes.func.isRequired,
  onShowPrivacy: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default FormCliente;
