import React, { useEffect } from "react";

const Landing = (props) => {
  useEffect(() => {
    window.location.replace("https://www.noloshop.it");
  });

  return <div>NOLOSHOP ONLINE</div>;
};

export default Landing;
