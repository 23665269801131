import React, { useState, useEffect } from "react";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import "./dates.css";
import "./App.scss";
import "./assets/fomantic/dist/semantic.css";
import { connect } from "react-redux";
import { Button, Container, Icon } from "semantic-ui-react";
import Footer from "./containers/Footer";
import NsHeader from "./containers/NsHeader";
import Shop from "./content/Shop";
import { Route, Switch, Redirect } from "react-router-dom";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import { useTranslation } from "react-i18next";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import Checkout from "./content/Checkout";
import Landing from "./content/Landing";
import * as shopActions from "./redux/actions/shopActions";
import * as cartActions from "./redux/actions/cartActions";
import CodeCheckRoute from "./routing/CodeCheckRoute";
import { useMediaQuery } from "react-responsive";
import Ok from "./content/Ok";
import Ko from "./content/Ko";
import Test from "./components/Test";
import AppendHead from "react-append-head";

function App({
  isLoading,
  datiNoleggio,
  nuoveRigheCarrello,
  //caricaDatiGenerali,
  setLang,
  setScrolled,
  aggiungiRigaOk,
  headerHeight,
  docScrolled,
  ownProps,
  ...props
}) {
  const [isOnTop, setIsOnTop] = useState(true);
  const [offsetTop, setOffsetTop] = useState("110px");
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.addEventListener("scroll", (e) => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 100) {
        setIsOnTop(false);
      } else {
        setIsOnTop(true);
      }
    });
  }, []);

  useEffect(() => {
    setScrolled(!isOnTop);

    if (!isOnTop) {
      let newHeight =
        parseInt(datiNoleggio.altezzaBarra) -
        (parseInt(datiNoleggio.altezzaBarra) / 100) * 30 +
        10;
      setOffsetTop(newHeight + "px");
    } else {
      setScrolled(false);
      let newHeight = parseInt(datiNoleggio.altezzaBarra) + 10;
      setOffsetTop(newHeight + "px");
    }
  }, [isOnTop]);

  useEffect(() => {
    if (datiNoleggio !== undefined) {
      //Aggiungo lo stile per il background fisso
      const backgroundStyle = document.createElement("style");
      backgroundStyle.type = "text/css";
      backgroundStyle.appendChild(
        document.createTextNode(
          `@media screen and (min-width: 1025px) {body {background-image: url("Noleggi/${datiNoleggio.codiceNoleggio}/sfondo.jpg");background-position: center center;background-size: cover;background-repeat: no-repeat;background-attachment: fixed;}}`
        )
      );

      document.getElementsByTagName("head")[0].appendChild(backgroundStyle);
    }
  }, [datiNoleggio.codiceNoleggio]);

  useEffect(() => {
    if (nuoveRigheCarrello) {
      toast({
        type: "success",
        icon: "plus",
        title: t("articolo_aggiunto_carrello"),
        description: "",
        time: 1200,
        size: "small",
      });
      aggiungiRigaOk();
    }
  }, [nuoveRigheCarrello]);

  return (
    <>
      <div className="noloshop_main">
        {datiNoleggio.codiceNoleggio !== undefined && (
          <AppendHead>
            <link
              name="customCss"
              rel="stylesheet"
              href={`${process.env.PUBLIC_URL}/Noleggi/${datiNoleggio.codiceNoleggio}/custom.css`}
            />
          </AppendHead>
        )}
        {!isOnTop && (
          <div className="to-top">
            <Button
              icon
              basic
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Icon name="arrow up" color="black"></Icon>
            </Button>
          </div>
        )}
        <NsHeader />
        <SemanticToastContainer className="container toastContainer" />
        <Container
          className={isMobile ? "ns-container-mobile" : "ns-container"}
          fluid={isMobile}
          style={{ marginTop: headerHeight + 7 + "px" }}
        >
          <Switch>
            <CodeCheckRoute
              exact
              path="/:lang/:codiceNoleggio/test"
              component={Test}
            />
            <Route
              exact
              path="/:codiceNoleggio"
              component={(props) => (
                <Redirect to={`/it/${props.match.params.codiceNoleggio}`} />
              )}
            />
            <CodeCheckRoute
              path="/:lang/:codiceNoleggio/"
              exact
              component={Shop}
            />
            <CodeCheckRoute
              path="/:lang/:codiceNoleggio/checkout"
              exact
              component={Checkout}
            />
            <CodeCheckRoute
              path="/:lang/:codiceNoleggio/checkout/:codiceordine/ok"
              exact
              component={Ok}
            />
            <CodeCheckRoute
              path="/:lang/:codiceNoleggio/checkout/:codiceordine/ko"
              exact
              component={Ko}
            />
            <Route path="/" component={Landing}></Route>
          </Switch>
        </Container>
      </div>
      <Footer></Footer>
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    lang: ownProps,
    datiNoleggio: state.shopReducer.datiNoleggio,
    nuoveRigheCarrello: state.cartReducer.nuoveRighe,
    headerHeight: state.shopReducer.headerHeight,
    docScrolled: state.shopReducer.scrolled,
  };
}

const mapDispatchToProps = {
  //caricaDatiGenerali: shopActions.CaricaDatiGenerali,
  setLang: shopActions.SetLingua,
  setScrolled: shopActions.SetScrolled,
  aggiungiRigaOk: cartActions.AggiungiRigaOk,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
