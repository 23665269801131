import * as actionTypes from "../actions/actionTypes";

const initialState = {
  repartiStatus: {},
};

export const ordineReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOP_CARICAREPARTI:
      //Intercetto il cariamento dei reparti per impostare la data minima
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            data: action.data,
          },
        },
      };
    case actionTypes.ORDINE_SETREPARTO_DATA:
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            data: action.data,
          },
        },
      };
    case actionTypes.ORDINE_SETREPARTO_GG:
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            giorni: action.giorni,
          },
        },
      };
    case actionTypes.ORDINE_SETREPARTO_MISURA:
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            misura: action.misura,
          },
        },
      };
    case actionTypes.ORDINE_SETREPARTO_DATIUTILIZZATORE:
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            [action.tipoDato]: action.dato,
          },
        },
      };

    case actionTypes.ORDINE_AGGIUNGIACCESSORIO:
      debugger;
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            accessori:
              state.repartiStatus[action.idReparto].accessori === undefined
                ? [action.idAccessorio]
                : [
                    ...state.repartiStatus[action.idReparto].accessori,
                    action.idAccessorio,
                  ],
          },
        },
      };

    case actionTypes.ORDINE_RIMUOVIACCESSORIO:
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            accessori: state.repartiStatus[action.idReparto].accessori.filter(
              (a) => a !== action.idAccessorio
            ),
          },
        },
      };

    case actionTypes.ORDINE_RIMUOVIACCESSORI:
      return {
        ...state,
        repartiStatus: {
          ...state.repartiStatus,
          [action.idReparto]: {
            ...state.repartiStatus[action.idReparto],
            accessori: [],
          },
        },
      };

    case actionTypes.ORDINE_CLEAR:
      return initialState;
    default:
      return state;
  }
};
