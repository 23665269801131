import * as actionTypes from "../actions/actionTypes";

const initialState = {
  lingua: "it",
  scrolled: false,
  headerHeight: 120,
  datiNoleggio: {},
  settori: [],
};

export const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOP_DATINOLEGGIO:
      return {
        ...state,
        datiNoleggio: action.dati,
        settori: action.dati.settori,
      };
    case actionTypes.SHOP_SETSETTORE:
      return {
        ...state,
        settoreAttivo: state.datiNoleggio.settori.find(
          (x) => x.idNoloshop === action.id
        ),
      };
    case actionTypes.SHOP_CARICAREPARTI:
      return {
        ...state,
        settori: state.settori.map((settore, id) =>
          settore.idNoloshop === action.idSettore
            ? { ...settore, reparti: action.dati }
            : settore
        ),
      };
    case actionTypes.SHOP_SETSCROLLED:
      return {
        ...state,
        scrolled: action.value,
      };
    case actionTypes.SHOP_SETHEADERHEIGHT:
      return {
        ...state,
        headerHeight: action.height,
      };
    case actionTypes.SHOP_SETLINGUA:
      return {
        ...state,
        lingua: action.lingua,
      };
    case actionTypes.ORDINE_CLEAR:
      return {
        ...state,
        settori: [],
      };
    default:
      return state;
  }
};
