import * as actionTypes from "../actions/actionTypes";

const initialState = {
  apiCalls: 0,
  isLoading: false,
  lastError: null,
};

export const apiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_CALL_BEGIN:
      return {
        ...state,
        apiCalls: state.apiCalls + 1,
      };
    case actionTypes.API_CALL_SUCCESS:
      return {
        ...state,
        apiCalls: state.apiCalls - 1,
      };
    case actionTypes.API_CALL_ERROR:
      return {
        ...state,
        apiCalls: state.apiCalls - 1,
        lastError: action.error.message,
      };
    default:
      return state;
  }
};
