import React from "react";
import PropTypes from "prop-types";
import { Button, Icon, Image } from "semantic-ui-react";
import moment from "moment";

const Accessorio = (props) => {
  const picUrl = `https://skinolo.blob.core.windows.net/webimg/${props.codNoleggio}/${props.accessorio.picture}`;

  const getPrezzo = () => {
    const listino = props.accessorio.listinoStandard.find(
      (l) =>
        moment(l.dal) <= moment(props.dataInizio) &&
        moment(l.al) >= moment(props.dataInizio) &&
        l.idFrazioneNoloShop === props.idFrazione
    );
    if (listino === undefined) return 0;
    return listino.prezzo.toFixed(2);
  };

  const getPrezzoApplicato = () => {
    const listino = props.accessorio.listinoApplicato.find(
      (l) =>
        moment(l.dal) <= moment(props.dataInizio) &&
        moment(l.al) >= moment(props.dataInizio) &&
        l.idFrazioneNoloShop === props.idFrazione
    );

    if (listino === undefined) return 0;
    return listino.prezzo.toFixed(2);
  };

  return (
    <div className="accessorio">
      <div className="accessorio_titolo">{props.accessorio.titolo}</div>
      <div
        className="accessorio_immagine"
        onClick={() => props.onClick(props.accessorio.idNoloshop)}
      >
        <Image src={picUrl} size="tiny" style={{ margin: "0 auto" }}></Image>
      </div>
      <div className="accessorio_prezzi">
        {getPrezzoApplicato() !== getPrezzo() && (
          <div className="accessorio_prezzo-listino">
            {props.t("prezzo_listino")}{" "}
            <span style={{ textDecoration: "line-through" }}>
              € {getPrezzo()}
            </span>
          </div>
        )}
        <div className="accessorio_prezzo-applicato-header">
          {props.t("prezzo_online")}
        </div>
        <div className="accessorio_prezzo-applicato">
          € {getPrezzoApplicato()}
        </div>
      </div>
      <div className="accessorio_azioni">
        {!props.aggiunto && (
          <Button
            fluid
            positive
            onClick={() => {
              props.onAggiungi(props.accessorio.idNoloshop);
            }}
          >
            <Icon name="plus" />
            {props.t("aggiungi")}
          </Button>
        )}
        {props.aggiunto && (
          <>
            <Button
              fluid
              negative
              onClick={() => {
                props.onRimuovi(props.accessorio.idNoloshop);
              }}
            >
              <Icon name="minus" />
              {props.t("rimuovi")}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

Accessorio.propTypes = {
  accessorio: PropTypes.object.isRequired,
  aggiunto: PropTypes.bool.isRequired,
  onAggiungi: PropTypes.func.isRequired,
  onRimuovi: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Accessorio;
