import * as actionTypes from "./actionTypes";

export function SetRepartoData(idReparto, data, defaultGiorni) {
  return {
    type: actionTypes.ORDINE_SETREPARTO_DATA,
    idReparto,
    data,
    defaultGiorni,
  };
}

export function SetRepartoGiorni(idReparto, giorni) {
  return {
    type: actionTypes.ORDINE_SETREPARTO_GG,
    idReparto,
    giorni,
  };
}

export function SetRepartoMisura(idReparto, misura) {
  return {
    type: actionTypes.ORDINE_SETREPARTO_MISURA,
    idReparto,
    misura,
  };
}

export function SetRepartoDatiUtilizzatore(idReparto, tipoDato, dato) {
  return {
    type: actionTypes.ORDINE_SETREPARTO_DATIUTILIZZATORE,
    idReparto,
    tipoDato,
    dato,
  };
}

export function AggiungiAccessorio(idReparto, idAccessorio) {
  return {
    type: actionTypes.ORDINE_AGGIUNGIACCESSORIO,
    idReparto,
    idAccessorio,
  };
}

export function RimuoviAccessorio(idReparto, idAccessorio) {
  return {
    type: actionTypes.ORDINE_RIMUOVIACCESSORIO,
    idReparto,
    idAccessorio,
  };
}

export function RimuoviAccessori(idReparto) {
  return {
    type: actionTypes.ORDINE_RIMUOVIACCESSORI,
    idReparto,
  };
}

export function ClearOrdine() {
  return {
    type: actionTypes.ORDINE_CLEAR,
  };
}
