import React from "react";
import { connect } from "react-redux";
import { Icon } from "semantic-ui-react";

const Footer = ({ datiNoleggio }) => {
  return (
    <div className="ns-footer">
      {" "}
      <p style={{ marginBottom: "0px" }}>
        Copyright <Icon name="copyright outline" />
        2021 - {datiNoleggio.denominazione}
      </p>
      <p style={{ marginTop: "0px" }}>
        {datiNoleggio.indirizzo} - {datiNoleggio.cap} {datiNoleggio.citta} (
        {datiNoleggio.provincia}) - tel. {datiNoleggio.telefono} -{" "}
        {datiNoleggio.eMail} - {datiNoleggio.sito} - P.Iva{" "}
        {datiNoleggio.partitaIva}
      </p>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoading: state.apiReducer.apiCalls > 0,
    datiNoleggio: state.shopReducer.datiNoleggio,
    lang: state.shopReducer.lingua,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
