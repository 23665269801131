import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Divider, Segment, Icon, Button } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import * as cartActions from "../../redux/actions/cartActions";

const Ok = ({ orderId, svuotaCarrello, lang, datiNoleggio }) => {
  useEffect(() => {
    svuotaCarrello();
  }, []);

  const history = useHistory();

  function openPdf(file) {
    window.open(`https://skinolo.blob.core.windows.net/ricevute/${file}`);
  }

  return (
    <div className="ok">
      <Segment color="green">
        <h2>TRANSAZIONE ANDATA A BUON FINE</h2>
        <Divider></Divider>
        <Segment basic>
          <h3>Scarica la ricevuta pdf</h3>
          <p>(riceverai comunque una copia sulla tua email)</p>
          <Icon
            name="file pdf outline"
            size="massive"
            color="red"
            style={{ cursor: "pointer" }}
            onClick={() => {
              openPdf(`${orderId}.pdf`);
            }}
          />

          <h3>Grazie per aver scelto il nostro noleggio!</h3>
        </Segment>
      </Segment>
      <Button
        primary
        onClick={() => {
          history.push(`/${lang}/${datiNoleggio.codiceNoleggio}`);
          window.scrollTo(0, 0);
        }}
      >
        TORNA ALLO SHOP
      </Button>
    </div>
  );
};
function mapStateToProps(state, ownProps) {
  return {
    orderId: ownProps.match.params.codiceordine,
    codiceNoleggio: ownProps.match.params.codiceNoleggio,
    lang: state.shopReducer.lingua,
    datiNoleggio: state.shopReducer.datiNoleggio,
  };
}

const mapDispatchToProps = {
  svuotaCarrello: cartActions.SvuotaCarrello,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ok);
