import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Icon, Modal, Image } from "semantic-ui-react";
//import { SemanticToastContainer, toast } from "react-semantic-toasts";
import Accessorio from "../Accessorio";
import RiepilogoReparto from "./RiepilogoReparto";
import moment from "moment";
import FormTipoSci from "./FormTipoSci";
import FormTipoNiente from "./FormTipoNiente";
import FormTipoCalzatura from "./FormTipoCalzatura";
import FormTipoBike from "./FormTipoBike";

const FormDettagli = (props) => {
  const [submitted, setSubmitted] = useState(false);
  const [showAccessorioDettagli, setShowAccessorioDettagli] = useState(-1);

  // const optionsProfilo = [
  //   { key: 0, text: "Principiante", value: 0 },
  //   { key: 1, text: "Intermedio", value: 1 },
  //   { key: 2, text: "Esperto", value: 2 },
  //   { key: 3, text: "Agonista", value: 3 },
  // ];

  // const optionsSesso = [
  //   { key: 0, text: "Uomo", value: "M" },
  //   { key: 1, text: "Donna", value: "F" },
  // ];

  // const optionsPeso = [
  //   { key: 0, text: "0-17 Kg", value: 0 },
  //   { key: 1, text: "18-21 Kg", value: 1 },
  //   { key: 2, text: "22-25 Kg", value: 2 },
  //   { key: 3, text: "26-30 Kg", value: 3 },
  //   { key: 4, text: "31-35 Kg", value: 4 },
  //   { key: 5, text: "36-41 Kg", value: 5 },
  //   { key: 6, text: "42-48 Kg", value: 6 },
  //   { key: 7, text: "49-57 Kg", value: 7 },
  //   { key: 8, text: "58-66 Kg", value: 8 },
  //   { key: 9, text: "67-78 Kg", value: 9 },
  //   { key: 10, text: "79-94 Kg", value: 10 },
  //   { key: 11, text: ">94 Kg", value: 11 },
  // ];

  // const optionsAltezza = [
  //   { key: 0, text: "90-95 cm", value: 0 },
  //   { key: 1, text: "96-100 cm", value: 1 },
  //   { key: 2, text: "101-105 cm", value: 2 },
  //   { key: 3, text: "106-110 cm", value: 3 },
  //   { key: 4, text: "111-115 cm", value: 4 },
  //   { key: 5, text: "116-120 cm", value: 5 },
  //   { key: 6, text: "121-125 cm", value: 6 },
  //   { key: 7, text: "126-130 cm", value: 7 },
  //   { key: 8, text: "131-135 cm", value: 8 },
  //   { key: 9, text: "136-140 cm", value: 9 },
  //   { key: 10, text: "141-145 cm", value: 10 },
  //   { key: 11, text: "146-150 cm", value: 11 },
  //   { key: 12, text: "151-155 cm", value: 12 },
  //   { key: 13, text: "156-160 cm", value: 13 },
  //   { key: 14, text: "161-165 cm", value: 14 },
  //   { key: 15, text: "166-170 cm", value: 15 },
  //   { key: 16, text: "171-175 cm", value: 16 },
  //   { key: 17, text: "176-180 cm", value: 17 },
  //   { key: 18, text: "181-185 cm", value: 18 },
  //   { key: 19, text: "186-190 cm", value: 19 },
  //   { key: 20, text: "191-195 cm", value: 20 },
  //   { key: 21, text: "196-200 cm", value: 21 },
  //   { key: 22, text: ">200 cm", value: 22 },
  // ];

  // const optionsScarpe = [
  //   { key: 0, text: "EU 25 / GB:7½ / US:8", value: 0 },
  //   { key: 1, text: "EU:26 / GB:8½ / US:9", value: 1 },
  //   { key: 2, text: "EU:27 / GB:9 / US:9.5", value: 2 },
  //   { key: 3, text: "EU:28 / GB:10 - US:10½", value: 3 },
  //   { key: 4, text: "EU:29 / GB:11 - US:11½", value: 4 },
  //   { key: 5, text: "EU:30 / GB:12 - US:12½", value: 5 },
  //   { key: 6, text: "EU:31 / GB:12½ - US:13", value: 6 },
  //   { key: 7, text: "EU:32 / GB:13½ - US:13½", value: 7 },
  //   { key: 8, text: "EU:33 / GB:1 / US:1", value: 8 },
  //   { key: 9, text: "EU:34 / GB:2 / US:2", value: 9 },
  //   { key: 10, text: "EU:35 / GB:2½ / US:M3½,F5", value: 10 },
  //   { key: 11, text: "EU:36 / GB:3½ / US:M4½,F6", value: 11 },
  //   { key: 12, text: "EU:37 / GB:4 / US:M5,F6½", value: 12 },
  //   { key: 13, text: "EU:38 / GB:5 / US:M6,F7½", value: 13 },
  //   { key: 14, text: "EU:39 / GB:6 / US:M7,F8½", value: 14 },
  //   { key: 15, text: "EU:40 / GB:6½ / US:M7½,F9", value: 15 },
  //   { key: 16, text: "EU:41 / GB:7 / US:M8,F9½", value: 16 },
  //   { key: 17, text: "EU:42 / GB:7½ / US:M8½,F10", value: 17 },
  //   { key: 18, text: "EU:43 / GB:8 / US:M9,F10.5", value: 18 },
  //   { key: 19, text: "EU:44 / GB:9½ / US:M10½,F12", value: 19 },
  //   { key: 20, text: "EU:45 / GB:10½ / US:M11½,F13", value: 20 },
  //   { key: 21, text: "EU:46 / GB:11½ / US:M12½,F14", value: 21 },
  //   { key: 22, text: "EU:47 / GB:12 / US:M13", value: 22 },
  //   { key: 23, text: "EU:48 / GB:13 / US:M14", value: 23 },
  //   { key: 24, text: "EU:49 / GB:13½ / US:M14½", value: 24 },
  //   { key: 25, text: "EU:50 / GB:14 / US:M15", value: 25 },
  // ];

  const formRef = useRef();

  // const DcsSelect = ({ label, ...props }) => {
  //   const [field, meta, helpers] = useField(props.id);
  //   return (
  //     <Form.Field
  //       control={Select}
  //       label={label}
  //       error={
  //         meta.error === ""
  //           ? null
  //           : meta.error !== "" && submitted
  //           ? meta.error
  //           : null
  //       }
  //       onChange={(e, v) => helpers.setValue(v.value)}
  //       value={field.value !== undefined ? field.value : null}
  //       {...props}
  //     />
  //   );
  // };

  // const validateSchema = Yup.object({
  //   nome: Yup.string()
  //     .max(50, "Massimo 50 caratteri!")
  //     .required("Valore richiesto"),
  //   cognome: Yup.string()
  //     .max(50, "Massimo 50 caratteri!")
  //     .required("Valore richiesto"),
  //   profilo: Yup.string().required("Valore richiesto"),
  //   sesso: Yup.string().required("Valore richiesto"),
  //   peso: Yup.string().required("Valore richiesto"),
  //   altezza: Yup.string().required("Valore richiesto"),
  //   scarpe: Yup.string().required("Valore richiesto"),
  //   misura: props.checkDisponibilita
  //     ? Yup.string().required("Valore richiesto")
  //     : null,
  // });

  const getPrezzoAccessorio = (idAccessorio) => {
    debugger;
    const listino = props.accessori
      .find((a) => a.idNoloshop === idAccessorio)
      .listinoApplicato.find(
        (l) =>
          moment(l.dal) <= moment(props.dataInizio) &&
          moment(l.al) >= moment(props.dataInizio) &&
          l.numGiorni === props.numGiorni
      );
    if (listino === undefined) return 0;

    return listino.prezzo;
  };

  const getTitoloAccessorio = (idAccessorio) => {
    const accessorio = props.accessori.find(
      (a) => a.idNoloshop === idAccessorio
    );

    if (accessorio === undefined) return "";

    return accessorio.titolo;
  };

  return (
    <div className="form-dettagli">
      {(props.tipoUtilizzatore === 0 || props.tipoUtilizzatore === 1) && (
        <FormTipoNiente
          formRef={formRef}
          t={props.t}
          onAggiungiRigaCarrello={props.onAggiungiRigaCarrello}
          checkDisponibilita={props.checkDisponibilita}
          misureDisponibli={props.misureDisponibli}
          submitted={submitted}
        />
      )}
      {props.tipoUtilizzatore === 2 && (
        <FormTipoBike
          formRef={formRef}
          t={props.t}
          onAggiungiRigaCarrello={props.onAggiungiRigaCarrello}
          checkDisponibilita={props.checkDisponibilita}
          misureDisponibli={props.misureDisponibli}
          submitted={submitted}
        />
      )}
      {props.tipoUtilizzatore === 3 && (
        <FormTipoSci
          formRef={formRef}
          t={props.t}
          onAggiungiRigaCarrello={props.onAggiungiRigaCarrello}
          checkDisponibilita={props.checkDisponibilita}
          misureDisponibli={props.misureDisponibli}
          submitted={submitted}
        />
      )}
      {props.tipoUtilizzatore === 4 && (
        <FormTipoCalzatura
          formRef={formRef}
          t={props.t}
          onAggiungiRigaCarrello={props.onAggiungiRigaCarrello}
          checkDisponibilita={props.checkDisponibilita}
          misureDisponibli={props.misureDisponibli}
          submitted={submitted}
        />
      )}
      <div className="form-dettagli_accessori_riepilogo">
        {props.accessori.length > 0 && (
          <div className="form-dettagli_accessori">
            <h3 className="form-dettagli_accessori_titolo">
              {props.t("accessori_disponibili")}
            </h3>

            <div className="form-dettagli_accessori_lista">
              {props.accessori.map((acc) => (
                <Accessorio
                  codNoleggio={props.codNoleggio}
                  accessorio={acc}
                  t={props.t}
                  numGiorni={props.numGiorni}
                  idFrazione={props.idFrazione}
                  dataInizio={props.dataInizio}
                  onAggiungi={(idAccessorio) => {
                    props.onAggiungiAccessorio(idAccessorio);
                    // toast({
                    //   type: "success",
                    //   icon: "check",
                    //   title: "Accessorio aggiunto",
                    //   description: `${acc.titolo} aggiunto all'ordine`,
                    //   time: 2000,
                    // });
                  }}
                  onRimuovi={(idAccessorio) => {
                    props.onRimuoviAccessorio(idAccessorio);
                    // toast({
                    //   type: "error",
                    //   icon: "x",
                    //   title: "Accessorio rimosso",
                    //   description: `${acc.titolo} rimosso dall'ordine`,
                    //   time: 2000,
                    // });
                  }}
                  onClick={(id) => {
                    setShowAccessorioDettagli(id);
                  }}
                  aggiunto={
                    props.datiOrdine.accessori === undefined
                      ? false
                      : props.datiOrdine.accessori.includes(acc.idNoloshop)
                  }
                ></Accessorio>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="form-dettagli_riepilogo">
        <RiepilogoReparto
          t={props.t}
          prezzoReparto={parseFloat(props.prezzoReparto)}
          accessori={
            props.datiOrdine.accessori === undefined
              ? []
              : props.datiOrdine.accessori.map((a) => ({
                  idAccessorio: a,
                  titolo: getTitoloAccessorio(a),
                  prezzo: getPrezzoAccessorio(a),
                }))
          }
        />
      </div>
      <Divider />
      <Button secondary onClick={props.onClose} style={{ marginBottom: "7px" }}>
        <Icon name="x"></Icon>
        {props.t("chiudi")}
      </Button>
      <Button
        type="submit"
        primary
        onClick={() => {
          setSubmitted(true);
          if (formRef.current) {
            formRef.current.handleSubmit();
          }
        }}
      >
        <Icon name="cart plus"></Icon>
        {props.t("metti_nel_carrello")}
      </Button>
      {showAccessorioDettagli > 0 && (
        <Modal
          size="mini"
          open={showAccessorioDettagli > 0}
          onClose={() => {
            setShowAccessorioDettagli(-1);
          }}
        >
          <Modal.Header>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Image
                src={`https://skinolo.blob.core.windows.net/webimg/${
                  props.codNoleggio
                }/${
                  props.accessori.find(
                    (a) => a.idNoloshop === showAccessorioDettagli
                  ).picture
                }`}
                size="tiny"
              />
              <div style={{ verticalAlign: "middle" }}>
                {
                  props.accessori.find(
                    (a) => a.idNoloshop === showAccessorioDettagli
                  ).titolo
                }
              </div>
            </div>
          </Modal.Header>
          <Modal.Content
            dangerouslySetInnerHTML={{
              __html: props.accessori.find(
                (a) => a.idNoloshop === showAccessorioDettagli
              ).descrizione,
            }}
          ></Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => {
                setShowAccessorioDettagli(-1);
              }}
            >
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

FormDettagli.propTypes = {
  codNoleggio: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  accessori: PropTypes.array.isRequired,
  prezzoReparto: PropTypes.number.isRequired,
  tipoUtilizzatore: PropTypes.number.isRequired,
  datiOrdine: PropTypes.object.isRequired,
  dataInizio: PropTypes.object.isRequired,
  numGiorni: PropTypes.number.isRequired,
  idFrazione: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onAggiungiAccessorio: PropTypes.func.isRequired,
  onRimuoviAccessorio: PropTypes.func.isRequired,
};

export default FormDettagli;
