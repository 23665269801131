import * as types from "./actionTypes";

export const apiCallBegin = () => {
  return {
    type: types.API_CALL_BEGIN,
  };
};

export const apiCallSuccess = () => {
  return {
    type: types.API_CALL_SUCCESS,
  };
};

export const apiCallError = (error) => {
  return {
    type: types.API_CALL_ERROR,
    error,
  };
};

//Api Action Creator
export function apiAction({
  url = "",
  method = "GET",
  data = null,
  onSuccess = null,
  onFailure = null,
  responseType = null,
  scope = "SHOP",
}) {
  return {
    type: types.API,
    payload: {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      responseType,
      scope,
    },
  };
}
