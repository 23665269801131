import React from "react";
import { Form, Select } from "semantic-ui-react";
import { useField } from "formik";
import PropTypes from "prop-types";

const DcsSelect = ({ label, ...props }) => {
  const [field, meta, helpers] = useField(props.id);
  return (
    <Form.Field
      control={Select}
      label={label}
      error={
        meta.error === ""
          ? null
          : meta.error !== "" && props.submitted
          ? meta.error
          : null
      }
      onChange={(e, v) => {
        console.log(v.value);
        helpers.setValue(v.value);
      }}
      value={field.value !== undefined ? field.value : null}
      {...props}
    />
  );
};

DcsSelect.propTypes = {
  id: PropTypes.number.isRequired,
  submitted: PropTypes.bool.isRequired,
};

export default DcsSelect;
