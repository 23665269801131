import React from "react";
import { Formik } from "formik";
import { Form, Label, Divider } from "semantic-ui-react";
import PropTypes from "prop-types";
import * as Yup from "yup";
//import DcsSelect from "../DcsSelect";
import FormikErrorFocus from "formik-error-focus";

const FormTipoNiente = (props) => {
  const validateSchema = Yup.object({
    nome: Yup.string()
      .max(50, props.t("max_50"))
      .required(props.t("valore_richiesto")),
    cognome: Yup.string()
      .max(50, props.t("max_50"))
      .required(props.t("valore_richiesto")),
  });

  return (
    <Formik
      initialValues={{ nome: "", cognome: "" }}
      validationSchema={validateSchema}
      onSubmit={(values, { resetForm }) => {
        props.onAggiungiRigaCarrello(values);
        resetForm({ nome: "", cognome: "" });
      }}
      enableReinitialize
      innerRef={props.formRef}
    >
      {({ setFieldValue, ...formik }) => {
        return (
          <Form widths="equal" onSubmit={formik.handleSubmit}>
            {props.checkDisponibilita && (
              <>
                <h3>{props.t("misura")}</h3>
                <div>
                  <Form.Group
                    inline
                    style={{ textAlign: "center", justifyContent: "center" }}
                  >
                    {props.misureDisponibli.map((m) => (
                      <div>
                        <Form.Radio
                          label={m.misura}
                          name="misura"
                          disabled={m.disabled}
                          onChange={() => setFieldValue("misura", m.misura)}
                          value={m.misura}
                          checked={formik.values.misura === m.misura}
                        />
                        {m.disabled && (
                          <div>
                            <div>{props.t("non_disponibile_date_riga1")}</div>
                            <div>{props.t("non_disponibile_date_riga2")}</div>
                            <div>{props.t("non_disponibile_date_riga3")}</div>
                          </div>
                        )}
                      </div>
                    ))}
                  </Form.Group>
                  {formik.values.misura === undefined && formik.dirty && (
                    <Label basic pointing prompt color="red">
                      {props.t("misura_necessaria")}
                    </Label>
                  )}
                </div>
              </>
            )}
            <Divider></Divider>
            <h3>{props.t("dati_utilizzatore")}</h3>
            <Form.Group>
              <Form.Input
                fluid
                label={props.t("nome")}
                placeholder={props.t("nome")}
                id="nome"
                {...formik.getFieldProps("nome")}
                error={formik.touched.nome && formik.errors.nome}
              ></Form.Input>
              <Form.Input
                fluid
                label={props.t("cognome")}
                placeholder={props.t("cognome")}
                id="cognome"
                {...formik.getFieldProps("cognome")}
                error={formik.touched.cognome && formik.errors.cognome}
              ></Form.Input>
            </Form.Group>
            <FormikErrorFocus
              // See scroll-to-element for configuration options: https://www.npmjs.com/package/scroll-to-element
              offset={-150}
              align={"top"}
              focusDelay={150}
              ease={"out-bounce"}
              duration={500}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

FormTipoNiente.propTypes = {
  formRef: PropTypes.object.isRequired,
  onAggiungiRigaCarrello: PropTypes.func.isRequired,
  checkDisponibilita: PropTypes.bool.isRequired,
  misureDisponibli: PropTypes.array.isRequired,
};

export default FormTipoNiente;
