import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Grid, List, Segment, Select } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import Reparto from "../Reparto/Reparto";
import moment from "moment";

const Reparti = (props) => {
  return (
    <div className="reparti">
      {props.reparti.length === 0 && <div>{props.t("nessun_articolo")}</div>}
      <List>
        {props.reparti.map((x) => (
          <List.Item key={x.idNoloshop}>
            <Reparto
              t={props.t}
              reparto={x}
              onSelect={() => {}}
              dataInizio={props.dataInizio}
              idFrazione={props.idFrazione}
              stagionale={props.stagionale}
              numGiorni={props.numGiorni}
              //onDataCambiata={props.onDataCambiata}
              //onGiorniCambiati={props.onGiorniCambiati}
              onMisuraCambiata={props.onMisuraCambiata}
              onDatiUtilizzatoreCambiati={props.onDatiUtilizzatoreCambiati}
              onAggiungiAccessorio={props.onAggiungiAccessorio}
              onRimuoviAccessorio={props.onRimuoviAccessorio}
              dataDal={
                props.datiOrdine[x.idNoloshop] === undefined
                  ? null
                  : props.datiOrdine[x.idNoloshop].data
              }
              giorni={
                props.datiOrdine[x.idNoloshop] === undefined
                  ? 1
                  : props.datiOrdine[x.idNoloshop].giorni
              }
              codNoleggio={props.codNoleggio}
              misura={
                props.datiOrdine[x.idNoloshop] === undefined
                  ? null
                  : props.datiOrdine[x.idNoloshop].misura
              }
              datiOrdine={props.datiOrdine[x.idNoloshop]}
              onAggiungiRigaCarrello={(datiCliente) => {
                props.onAggiungiRigaCarrello(x.idNoloshop, datiCliente);
              }}
            ></Reparto>
          </List.Item>
        ))}
      </List>
    </div>
  );
};

Reparti.propTypes = {
  reparti: PropTypes.array,
  settore: PropTypes.string.isRequired,
  dataInizio: PropTypes.object.isRequired,
  idFrazione: PropTypes.number.isRequired,
  datiOrdine: PropTypes.object.isRequired,
  //onDataCambiata: PropTypes.func.isRequired,
  //onGiorniCambiati: PropTypes.func.isRequired,
  onMisuraCambiata: PropTypes.func.isRequired,
  onDatiUtilizzatoreCambiati: PropTypes.func.isRequired,
  onAggiungiAccessorio: PropTypes.func.isRequired,
  onRimuoviAccessorio: PropTypes.func.isRequired,
  onAggiungiRigaCarrello: PropTypes.func.isRequired,
  codNoleggio: PropTypes.string.isRequired,
};

export default Reparti;
