import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Icon, Modal } from "semantic-ui-react";

const RiepilogoCheckout = (props) => {
  const [idRigaSelezionata, setIdRigaSelezionata] = useState(-1);
  const [titoloSelezionato, setTitoloSelezionato] = useState("");
  const [rimuoviRequest, setRimuoviRequest] = useState(false);
  const [infoVisualizzate, setInfoVisualizzate] = useState(false);
  const [totale, setTotale] = useState(0);
  const [caparra, setCaparra] = useState(0);

  useEffect(() => {
    setTotale(calcolaTotale().toFixed(2));
    setCaparra(calcolaTotaleDaVersare().toFixed(2));
  }, [props.righeCarrello]);

  const calcolaTotale = () => {
    if (props.righeCarrello.length === 0) return 0;
    let result = props.righeCarrello.reduce(
      (a, b) => a + parseFloat(b.prezzoApplicato),
      0
    );

    props.righeCarrello.forEach((r) => {
      if (r.accessori === undefined) return;
      if (r.accessori.length === 0) return;

      result += r.accessori.reduce(
        (a, b) => a + parseFloat(b.prezzoApplicato),
        0
      );
    });
    return result;
  };

  const calcolaTotaleDaVersare = () => {
    if (props.righeCarrello.length === 0) return 0;
    let result = props.righeCarrello.reduce(
      (a, b) =>
        a + (parseFloat(b.prezzoApplicato) / 100) * parseFloat(b.percCaparra),
      0
    );

    props.righeCarrello.forEach((r) => {
      if (r.accessori === undefined) return;
      if (r.accessori.length === 0) return;

      result += r.accessori.reduce(
        (a, b) =>
          a + (parseFloat(b.prezzoApplicato) / 100) * parseFloat(b.percCaparra),
        0
      );
    });
    return result;
  };

  const getProfilo = (idProfilo) => {
    switch (idProfilo) {
      case 0:
        return "Principiante";
      case 1:
        return "Intermedio";
      case 2:
        return "Esperto";
      case 3:
        return "Agonista";
      default:
        return "-/-";
    }
  };

  const getAltezza = (idAltezza) => {
    switch (idAltezza) {
      case 0:
        return "90-95 cm";
      case 1:
        return "96-100 cm";
      case 2:
        return "101-105 cm";
      case 3:
        return "106-110 cm";
      case 4:
        return "111-115 cm";
      case 5:
        return "116-120 cm";
      case 6:
        return "121-125 cm";
      case 7:
        return "126-130 cm";
      case 8:
        return "131-135 cm";
      case 9:
        return "136-140 cm";
      case 10:
        return "141-145 cm";
      case 11:
        return "146-150 cm";
      case 12:
        return "151-155 cm";
      case 13:
        return "156-160 cm";
      case 14:
        return "161-165 cm";
      case 15:
        return "166-170 cm";
      case 16:
        return "171-175 cm";
      case 17:
        return "176-180 cm";
      case 18:
        return "181-185 cm";
      case 19:
        return "186-190 cm";
      case 20:
        return "191-195 cm";
      case 21:
        return "196-200 cm";
      case 22:
        return ">200 cm";
      default:
        return "-/-";
    }
  };

  const getPeso = (idPeso) => {
    switch (idPeso) {
      case 0:
        return "0-17 Kg";
      case 1:
        return "18-21 Kg";
      case 2:
        return "22-25 Kg";
      case 3:
        return "26-30 Kg";
      case 4:
        return "31-35 Kg";
      case 5:
        return "36-41 Kg";
      case 6:
        return "42-48 Kg";
      case 7:
        return "49-57 Kg";
      case 8:
        return "58-66 Kg";
      case 9:
        return "67-78 Kg";
      case 10:
        return "79-94 Kg";
      case 11:
        return ">94 Kg";
      default:
        return "-/-";
    }
  };

  const getScarpe = (idScarpa) => {
    switch (idScarpa) {
      case 0:
        return "EU 25 / GB:7½ / US:8";
      case 1:
        return "EU:26 / GB:8½ / US:9";
      case 2:
        return "EU:27 / GB:9 / US:9.5";
      case 3:
        return "EU:28 / GB:10 - US:10½";
      case 4:
        return "EU:29 / GB:11 - US:11½";
      case 5:
        return "EU:30 / GB:12 - US:12½";
      case 6:
        return "EU:31 / GB:12½ - US:13";
      case 7:
        return "EU:32 / GB:13½ - US:13½";
      case 8:
        return "EU:33 / GB:1 / US:1";
      case 9:
        return "EU:34 / GB:2 / US:2";
      case 10:
        return "EU:35 / GB:2½ / US:M3½,F5";
      case 11:
        return "EU:36 / GB:3½ / US:M4½,F6";
      case 12:
        return "EU:37 / GB:4 / US:M5,F6½";
      case 13:
        return "EU:38 / GB:5 / US:M6,F7½";
      case 14:
        return "EU:39 / GB:6 / US:M7,F8½";
      case 15:
        return "EU:40 / GB:6½ / US:M7½,F9";
      case 16:
        return "EU:41 / GB:7 / US:M8,F9½";
      case 17:
        return "EU:42 / GB:7½ / US:M8½,F10";
      case 18:
        return "EU:43 / GB:8 / US:M9,F10.5";
      case 19:
        return "EU:44 / GB:9½ / US:M10½,F12";
      case 20:
        return "EU:45 / GB:10½ / US:M11½,F13";
      case 21:
        return "EU:46 / GB:11½ / US:M12½,F14";
      case 22:
        return "EU:47 / GB:12 / US:M13";
      case 23:
        return "EU:48 / GB:13 / US:M14";
      case 24:
        return "EU:49 / GB:13½ / US:M14½";
      case 25:
        return "EU:50 / GB:14 / US:M15";
      default:
        return "-/-";
    }
  };

  return (
    <div className="riepilogo-checkout">
      <div className="riepilogo-checkout_intestazione">
        {props.t("il_tuo_ordine")} <Icon name="cart" />
      </div>
      <Divider />

      {props.righeCarrello.map((r, idx) => {
        return (
          <div className="riepilogo-checkout_riga" key={idx}>
            <div className="riepilogo-checkout_riga_titolo">
              {r.nomeReparto} {r.misura !== undefined ? r.misura : ""}
            </div>
            <div>
              {new Date(r.dal).toLocaleDateString("it-IT")} -{" "}
              {new Date(r.al).toLocaleDateString("it-IT")} ({r.giorni} gg.)
            </div>
            <div className="riepilogo-checkout_riga_blocco-prezzo">
              {r.prezzo !== r.prezzoApplicato && (
                <div>
                  <span className="riepilogo-checkout_riga_blocco-prezzo-listino">
                    € {r.prezzo}
                  </span>{" "}
                  <span className="riepilogo-checkout_riga_blocco-prezzo-applicato">
                    € {r.prezzoApplicato}
                  </span>
                </div>
              )}
              {r.prezzo === r.prezzoApplicato && (
                <div className="riepilogo-checkout_riga_blocco-prezzo-applicato">
                  € {r.prezzoApplicato}
                </div>
              )}
            </div>
            {r.accessori.map((a, idx) => (
              <React.Fragment key={idx}>
                <div>+ {a.nomeReparto}</div>
                {a.prezzo !== a.prezzoApplicato && (
                  <div>
                    <span className="riepilogo-checkout_riga_blocco-prezzo-listino">
                      € {a.prezzo}
                    </span>{" "}
                    <span className="riepilogo-checkout_riga_blocco-prezzo-applicato">
                      € {a.prezzoApplicato}
                    </span>
                  </div>
                )}
                {a.prezzo === a.prezzoApplicato && (
                  <div className="riepilogo-checkout_riga_blocco-prezzo-applicato">
                    € {a.prezzoApplicato}
                  </div>
                )}
              </React.Fragment>
            ))}
            <div className="riepilogo-checkout_riga_comandi">
              <Button
                primary
                onClick={() => {
                  setIdRigaSelezionata(idx);
                  setInfoVisualizzate(true);
                }}
              >
                <Icon name="info" />
                Info
              </Button>
              <Button
                negative
                onClick={() => {
                  setIdRigaSelezionata(idx);
                  setTitoloSelezionato(
                    r.nomeReparto +
                      " ( " +
                      new Date(r.dal).toLocaleDateString("it-IT") +
                      " - " +
                      new Date(r.al).toLocaleDateString("it-IT") +
                      " - " +
                      r.giorni +
                      "gg. )"
                  );
                  setRimuoviRequest(true);
                }}
              >
                <Icon name="trash" />
                Rimuovi
              </Button>
            </div>
          </div>
        );
      })}
      {totale !== caparra && (
        <>
          <div className="riepilogo-checkout_totale">TOTALE € {totale}</div>
          <div className="riepilogo-checkout_totale">CAPARRA € {caparra}</div>
        </>
      )}
      {totale === caparra && (
        <div className="riepilogo-checkout_totale">TOTALE € {totale}</div>
      )}

      {props.righeCarrello[idRigaSelezionata] !== undefined && (
        <>
          <Modal
            open={infoVisualizzate}
            className="riepilogo-checkout_modal-info"
            size="small"
          >
            <Modal.Header>
              <Icon name="info circle" size="large" color="blue" />
              Informazioni riga ordine
            </Modal.Header>
            <Modal.Content>
              <p className="riepilogo-checkout_modal-info_label">Articolo</p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {props.righeCarrello[idRigaSelezionata].nomeReparto}{" "}
                {props.righeCarrello[idRigaSelezionata].misura}
              </p>
              <p className="riepilogo-checkout_modal-info_label">
                Periodo noleggio
              </p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {new Date(
                  props.righeCarrello[idRigaSelezionata].dal
                ).toLocaleDateString("it-IT") +
                  " - " +
                  new Date(
                    props.righeCarrello[idRigaSelezionata].al
                  ).toLocaleDateString("it-IT") +
                  " - " +
                  props.righeCarrello[idRigaSelezionata].giorni +
                  "gg"}
              </p>
              {props.righeCarrello[idRigaSelezionata].accessori.length > 0 && (
                <>
                  <p className="riepilogo-checkout_modal-info_label">
                    Accessori
                  </p>
                  <p className="riepilogo-checkout_modal-info_accessori">
                    {props.righeCarrello[idRigaSelezionata].accessori.map(
                      (acc, idx) => (
                        <p key={idx}>+ {acc.nomeReparto}</p>
                      )
                    )}
                  </p>
                </>
              )}
              <p className="riepilogo-checkout_modal-info_label">
                Dati Utilizzatore
              </p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {props.righeCarrello[idRigaSelezionata].datiCliente.nome}{" "}
                {props.righeCarrello[idRigaSelezionata].datiCliente.cognome}
              </p>
              <p className="riepilogo-checkout_modal-info_label">Sesso</p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {props.righeCarrello[idRigaSelezionata].datiCliente.sesso}
              </p>

              <p className="riepilogo-checkout_modal-info_label">Profilo</p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {getProfilo(
                  props.righeCarrello[idRigaSelezionata].datiCliente.profilo
                )}
              </p>

              <p className="riepilogo-checkout_modal-info_label">Altezza</p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {getAltezza(
                  props.righeCarrello[idRigaSelezionata].datiCliente.altezza
                )}
              </p>

              <p className="riepilogo-checkout_modal-info_label">Peso</p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {getPeso(
                  props.righeCarrello[idRigaSelezionata].datiCliente.peso
                )}
              </p>

              <p className="riepilogo-checkout_modal-info_label">Scarpe</p>
              <p className="riepilogo-checkout_modal-info_elemento">
                {getScarpe(
                  props.righeCarrello[idRigaSelezionata].datiCliente.scarpe
                )}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="black"
                onClick={() => {
                  setInfoVisualizzate(false);
                }}
              >
                Chiudi
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={rimuoviRequest} size="small">
            <Modal.Header>Rimuovi articolo</Modal.Header>
            <Modal.Content>
              Sei sicuro di voler rimuovere l'articolo{" "}
              <span style={{ fontWeight: "bold" }}>{titoloSelezionato}</span> ?
            </Modal.Content>
            <Modal.Actions>
              <Button
                color="black"
                onClick={() => {
                  setRimuoviRequest(false);
                }}
              >
                Annulla
              </Button>
              <Button
                negative
                onClick={() => {
                  setRimuoviRequest(false);
                  props.onRimuoviRiga(idRigaSelezionata);
                }}
              >
                <Icon name="trash" />
                Si, sono sicuro
              </Button>
            </Modal.Actions>
          </Modal>
        </>
      )}
    </div>
  );
};

RiepilogoCheckout.propTypes = {
  righeCarrello: PropTypes.array.isRequired,
  onRimuoviRiga: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default RiepilogoCheckout;
