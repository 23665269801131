import axios from "axios";

const npAxios = axios.create({
  baseURL: process.env.NPURL,
  timeout: 30000,
  headers: {
    ApiKey: process.env.REACT_APP_APIKEY,
    "Content-Type": "application/json",
  },
});

const requestHandler = (request) => {
  if (request.npScope === "MANAGEMENT") {
    request.headers.Authorization =
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTIzNDU2Nzg5IiwibmFtZSI6IlNhbXBsZSIsImlhdCI6MTUxNjIzODIzfQ.ZEBwz4pWYGqgFJc6DIi7HdTN0z5Pfs4Lcv4ZNwMr1rs";
  }

  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  console.log(
    `***ERRORE chiamata (${error.response.config.method}) su '${error.response.config.url}', Status ${error.response.status}`
  );
  //console.log(error.response.data);

  //Se il 401 è su una richiesta nella zona di gestione, provo a rinfrescare il Token
  if (error.response.status === 401 && error.config.npScope === "MANAGEMENT") {
    //REFRESH!
  }

  return Promise.reject(error);
};

npAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

npAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default npAxios;
