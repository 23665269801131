import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Menu, Image, Icon, Label, Button, Modal } from "semantic-ui-react";
import { useHistory, useLocation } from "react-router-dom";
import * as shopActions from "../../redux/actions/shopActions";

const NsHeader = ({
  datiNoleggio,
  carrello,
  //lang,
  scrolled,
  headerHeight,
  setHeaderHeight,
  setLingua,
  isLoading,
  apiCalls,
}) => {
  const { t, i18n } = useTranslation();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  //const [headerHeight, setHeaderHeight] = useState("120px");
  const [logoWidth, setLogoWidth] = useState("120px");
  //const [isCheckout, setIsCheckout] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const history = useHistory();
  const location = useLocation();
  const logoImgPath = `/Noleggi/${datiNoleggio.codiceNoleggio}/logo.png`;

  // useEffect(() => {
  //   setIsCheckout(location.pathname.match(/checkout/i));
  // }, [location.pathname]);

  useEffect(() => {
    if (scrolled || isMobile || location.pathname.match(/checkout/i)) {
      let newHeight =
        parseInt(datiNoleggio.altezzaBarra) -
        (parseInt(datiNoleggio.altezzaBarra) / 100) * 40;
      setHeaderHeight(newHeight);
      let newWidth =
        parseInt(datiNoleggio.larghezzaLogo) -
        (parseInt(datiNoleggio.larghezzaLogo) / 100) * 40;
      setLogoWidth(newWidth + "px");
    } else {
      setHeaderHeight(parseInt(datiNoleggio.altezzaBarra));
      setLogoWidth(datiNoleggio.larghezzaLogo);
    }
  }, [
    scrolled,
    datiNoleggio.altezzaBarra,
    datiNoleggio.larghezzaLogo,
    isMobile,
    location.pathname,
  ]);

  return (
    <div>
      {isMobile && (
        <div
          className="ns-header"
          style={{
            maxHeight: headerHeight + "px",
            height: headerHeight + "px",
          }}
        >
          <img
            alt="logo"
            as="a"
            className="ns-header_logo"
            style={{
              position: "absolute",
              width: logoWidth,
            }}
            src={logoImgPath}
            onClick={() => {
              history.push(`/${i18n.language}/${datiNoleggio.codiceNoleggio}`);
              window.scrollTo(0, 0);
            }}
          ></img>
          <div style={{ float: "Right", height: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div className="ns-header_comandi">
                <Menu compact>
                  <Menu.Item
                    icon
                    key="home"
                    onClick={() => setIsMobileMenuOpen(true)}
                  >
                    <Icon name="sidebar" size="large" />
                  </Menu.Item>
                  {carrello !== undefined && carrello.length > 0 && (
                    <Menu.Item
                      icon
                      onClick={() => {
                        history.push(
                          `/${i18n.language}/${datiNoleggio.codiceNoleggio}/checkout`
                        );
                        window.scrollTo(0, 0);
                      }}
                    >
                      <Icon name="cart" size="large" />
                      {carrello.length > 0 && (
                        <Label color="yellow" floating size="large">
                          {carrello.length}
                        </Label>
                      )}
                    </Menu.Item>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </div>
      )}
      {!isMobile && (
        <div
          className="ns-header"
          style={{
            maxHeight: headerHeight + "px",
            height: headerHeight + "px",
          }}
        >
          <img
            alt="logo"
            as="a"
            className="ns-header_logo"
            style={{
              position: "absolute",
              width: logoWidth,
            }}
            src={logoImgPath}
            onClick={() => {
              history.push(`/${i18n.language}/${datiNoleggio.codiceNoleggio}`);
              window.scrollTo(0, 0);
            }}
          ></img>
          <div style={{ float: "Right", height: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <div>cart</div> */}
              <div className="ns-header_comandi">
                <Menu compact>
                  <Menu.Item
                    icon
                    key="home"
                    onClick={() => {
                      history.push(
                        `/${i18n.language}/${datiNoleggio.codiceNoleggio}`
                      );
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Icon name="home" size="large" />
                  </Menu.Item>
                  {carrello !== undefined && carrello.length > 0 && (
                    <Menu.Item
                      icon
                      onClick={() => {
                        history.push(
                          `/${i18n.language}/${datiNoleggio.codiceNoleggio}/checkout`
                        );

                        window.scrollTo(0, 0);
                      }}
                    >
                      <Icon name="cart" size="large" />
                      {carrello.length > 0 && (
                        <Label
                          color="yellow"
                          floating
                          size="large"
                          style={{ marginTop: "7px" }}
                        >
                          {carrello.length}
                        </Label>
                      )}
                    </Menu.Item>
                  )}
                </Menu>
              </div>
              {datiNoleggio.lingue !== undefined && (
                <div className="ns-header_lingue">
                  <Menu compact>
                    {datiNoleggio.lingue.map((x) => {
                      const imageFlag =
                        require(`../../assets/img/flags/noborder/${x.toUpperCase()}.jpg`).default;
                      return (
                        <Menu.Item
                          key={x}
                          onClick={() => {
                            const newCode =
                              x.toLowerCase() === "gb" ? "en" : x.toLowerCase();
                            history.push(
                              `/${newCode}/${datiNoleggio.codiceNoleggio}`
                            );
                            //setLingua(newCode);
                            history.go(0);
                            i18n.changeLanguage(newCode);
                          }}
                          className="skiwork-lang-menuitem"
                        >
                          <Image
                            src={imageFlag}
                            alt={x.toLowerCase()}
                            style={{
                              width: "34px",
                            }}
                          />
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        open={isMobileMenuOpen}
        closeIcon
        closeOnDimmerClick
        closeOnDocumentClick
        onClose={() => setIsMobileMenuOpen(false)}
      >
        <Modal.Content style={{ textAlign: "center" }}>
          <Menu vertical fluid>
            <Menu.Header className="ns-header_mobile-menu-header">
              MAIN
            </Menu.Header>
            <Menu.Item
              as="a"
              onClick={() => {
                history.push(
                  `/${i18n.language}/${datiNoleggio.codiceNoleggio}`
                );
                setIsMobileMenuOpen(false);
                window.scrollTo(0, 0);
              }}
            >
              <span style={{ fontSize: "24px" }}>HOME</span>
              <Icon size="big" name="home" />
            </Menu.Item>
            {carrello !== undefined && carrello.length > 0 && (
              <Menu.Item
                as="a"
                onClick={() => {
                  history.push(
                    `/${i18n.language}/${datiNoleggio.codiceNoleggio}/checkout`
                  );
                  setIsMobileMenuOpen(false);
                  window.scrollTo(0, 0);
                }}
              >
                <span style={{ fontSize: "24px" }}>CARRELLO</span>
                <Icon name="cart" size="big" />
                {carrello.length > 0 && (
                  <Label color="yellow" floating size="big">
                    {carrello.length}
                  </Label>
                )}
              </Menu.Item>
            )}
            <Menu.Item></Menu.Item>
            <div style={{ height: "24px" }}> </div>
            <Menu.Header className="ns-header_mobile-menu-header">
              LINGUE
            </Menu.Header>
            {datiNoleggio.lingue !== undefined &&
              datiNoleggio.lingue.map((x) => {
                const imageFlag =
                  require(`../../assets/img/flags/noborder/${x.toUpperCase()}.jpg`).default;
                return (
                  <Menu.Item
                    as="a"
                    onClick={() => {
                      const newCode =
                        x.toLowerCase() === "gb" ? "en" : x.toLowerCase();
                      history.push(
                        `/${newCode}/${datiNoleggio.codiceNoleggio}`
                      );
                      history.go(0);
                      i18n.changeLanguage(newCode);
                      setIsMobileMenuOpen(false);
                    }}
                  >
                    <span style={{ fontSize: "24px" }}>{x.toUpperCase()}</span>
                    <Image
                      src={imageFlag}
                      alt={x.toLowerCase()}
                      style={{ float: "right" }}
                      size="mini"
                    ></Image>
                  </Menu.Item>
                );
              })}
          </Menu>
          {/* <Button fluid>HOME</Button>
          <Button fluid>CARRELLO</Button>
          <Divider></Divider>
          <Button fluid>IT</Button>
          <Button fluid>DE</Button>
          <Button fluid>EN</Button> */}
        </Modal.Content>
      </Modal>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoading: state.apiReducer.apiCalls > 0,
    apiCalls: state.apiReducer.apiCalls,
    datiNoleggio: state.shopReducer.datiNoleggio,
    carrello: state.cartReducer.righe,
    //lang: state.shopReducer.lingua,
    scrolled: state.shopReducer.scrolled,
    headerHeight: state.shopReducer.headerHeight,
  };
}

const mapDispatchToProps = {
  setHeaderHeight: shopActions.SetHeaderHeight,
  setLingua: shopActions.SetLingua,
};

export default connect(mapStateToProps, mapDispatchToProps)(NsHeader);
