import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Loader,
  Rail,
  Sticky,
  Modal,
  Button,
  Icon,
  Dimmer,
} from "semantic-ui-react";
import * as gestionePagamenti from "../../utils/GestionePagamenti";
import FormCliente from "../../components/FormCliente";
import RiepilogoCheckout from "../../components/RiepilogoCheckout";
import * as cartActions from "../../redux/actions/cartActions";
import { Redirect } from "react-router";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const Checkout = ({
  isLoading,
  righeCarrello,
  datiNoleggio,
  rimuoviRiga,
  confermaCarrello,
  svuotaCarrello,
  codNoleggioCarrello,
  lang,
  ...props
}) => {
  const [noDispWarning, setNoDispWarning] = useState(false);
  const [genericErrorWarning, setGenericErrorWarning] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCondizioni, setShowCondizioni] = useState(false);
  const checkoutRef = useRef(null);
  const { t, i18n } = useTranslation();

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const routeMatch = useRouteMatch();
  const history = useHistory();

  const handleSubmit = (values) => {
    //verifico che il carrello sia dello stesso noleggio in uso
    console.log("codNoleggioCarrello: " + codNoleggioCarrello);
    console.log(
      "routeMatch.params.codiceNoleggio: " + routeMatch.params.codiceNoleggio
    );

    if (codNoleggioCarrello === undefined) {
      alert("Network error, please retry");
      cartActions.SvuotaCarrello();
      history.push(`/${i18n.language}/${datiNoleggio.codiceNoleggio}/`);

      return;
    }
    if (
      routeMatch.params.codiceNoleggio.toLowerCase() !==
      codNoleggioCarrello.toLowerCase()
    ) {
      alert("Network error, please retry");
      cartActions.SvuotaCarrello();
      history.push(`/${i18n.language}/${datiNoleggio.codiceNoleggio}/`);

      return;
    }

    //thunk di conferma
    confermaCarrello(
      datiNoleggio.codiceNoleggio,
      values,
      righeCarrello,
      (error) => {
        if (error.data.errorMessage === "NODISP") {
          setNoDispWarning(true);
        } else {
          setGenericErrorWarning(true);
        }
      },
      (data) => {
        gestionePagamenti.EffettuaPagamento(data);
      }
    );
  };

  console.log(routeMatch);

  return (
    <div className="checkout">
      {righeCarrello === undefined && (
        <Redirect to={`/${lang}/${datiNoleggio.codiceNoleggio}`} />
      )}
      {righeCarrello.length === 0 && (
        <Redirect to={`/${lang}/${datiNoleggio.codiceNoleggio}`} />
      )}
      <h2>CHECKOUT</h2>
      <Dimmer active={isLoading}>
        <Loader size="massive" active={isLoading}>
          {t("invio_dati_pagamento")}
        </Loader>
      </Dimmer>
      {!isLoading && !isMobile && (
        <Grid columns="2">
          <Grid.Column width="10">
            <FormCliente
              t={t}
              onSubmit={handleSubmit}
              onShowCondizioni={() => setShowCondizioni(true)}
              onShowPrivacy={() => setShowPrivacy(true)}
              isMobile={isMobile}
            ></FormCliente>
          </Grid.Column>
          <Grid.Column width="6">
            <RiepilogoCheckout
              t={t}
              righeCarrello={righeCarrello}
              onRimuoviRiga={rimuoviRiga}
              isMobile={isMobile}
            />
            {/* <Rail internal position="right" className="checkout_rail-ordine">
              <Sticky context={checkoutRef.current}>
                <RiepilogoCheckout
                  t={t}
                  righeCarrello={righeCarrello}
                  onRimuoviRiga={rimuoviRiga}
                  isMobile={isMobile}
                />
              </Sticky>
            </Rail> */}
          </Grid.Column>
        </Grid>
      )}
      {!isLoading && isMobile && (
        <div className="checkout_main">
          <RiepilogoCheckout
            t={t}
            righeCarrello={righeCarrello}
            onRimuoviRiga={rimuoviRiga}
            isMobile={isMobile}
          />
          <FormCliente
            t={t}
            onSubmit={handleSubmit}
            onShowCondizioni={() => setShowCondizioni(true)}
            onShowPrivacy={() => setShowPrivacy(true)}
            isMobile={isMobile}
          >
            {" "}
          </FormCliente>
        </div>
      )}
      <Modal
        //size="small"
        open={showPrivacy}
        onClose={() => {
          setShowPrivacy(false);
        }}
      >
        <Modal.Header>
          <Icon name="info" size="large" color="blue" />
          {t("informativa_privacy")}
        </Modal.Header>
        <Modal.Content
          dangerouslySetInnerHTML={{
            __html: datiNoleggio.testoPrivacy,
          }}
        ></Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setShowPrivacy(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        //size="small"
        open={showCondizioni}
        onClose={() => {
          setShowCondizioni(false);
        }}
      >
        <Modal.Header>
          <Icon name="info" size="large" color="blue" />
          {t("condizioni_vendita")}
        </Modal.Header>
        <Modal.Content
          dangerouslySetInnerHTML={{
            __html: datiNoleggio.testoCondizioniVendita,
          }}
        ></Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setShowCondizioni(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="small"
        open={noDispWarning}
        onClose={() => {
          setNoDispWarning(false);
        }}
      >
        <Modal.Header>
          <Icon name="times circle" size="large" color="red" />
          Acquisto non possibile
        </Modal.Header>
        <Modal.Content>
          <p>Alcuni articoli non sono disponibili nel periodo da te scelto</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setNoDispWarning(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="small"
        open={genericErrorWarning}
        onClose={() => {
          setGenericErrorWarning(false);
        }}
      >
        <Modal.Header>
          <Icon name="times circle" size="large" color="red" />
          Acquisto non possibile in questo momento
        </Modal.Header>
        <Modal.Content>
          <p>Si è verificato un problema, riprova più tardi</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              setGenericErrorWarning(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    isLoading: state.apiReducer.apiCalls > 0,
    righeCarrello: state.cartReducer.righe,
    codNoleggioCarrello: state.cartReducer.codNoleggio,
    datiNoleggio: state.shopReducer.datiNoleggio,
    lang: state.shopReducer.lingua,
  };
}

const mapDispatchToProps = {
  rimuoviRiga: cartActions.RimuoviRiga,
  confermaCarrello: cartActions.ConfermaCarrello,
  svuotaCarrello: cartActions.SvuotaCarrello,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
