import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Grid, Icon, Image, Segment } from "semantic-ui-react";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import itLocale from "moment/locale/it";
import FormDettagli from "../FormDettagli";

const Reparto = (props) => {
  const [formVisualizzata, setFormVisualizzata] = useState(false);
  const [misureDisponibli, setMisureDisponibili] = useState([]);
  const [escluso, setEscluso] = useState(false);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });

  useEffect(() => {
    if (props.dataDal === undefined) return;

    const nonDisponibile = (misura) => {
      var esclusioniMisura = props.reparto.periodiEsclusiMisura.filter(
        (p) => p.misura === misura
      );

      const dal = new Date(props.dataInizio);
      const al = new Date(props.dataInizio);
      al.setDate(al.getDate() + props.numGiorni - 1);

      for (let i = 0; i < esclusioniMisura.length; i++) {
        const escDal = new Date(esclusioniMisura[i].dal).setHours(0, 0, 0, 0);
        const escAl = new Date(esclusioniMisura[i].al).setHours(0, 0, 0, 0);

        if (
          dal.setHours(0, 0, 0, 0) < escAl &&
          al.setHours(0, 0, 0, 0) >= escDal
        ) {
          return true;
        }
      }
      return false;
    };

    if (props.reparto.checkDisponibilita) {
      const misure = props.reparto.misure.map((m) => ({
        misura: m,
        disabled: nonDisponibile(m),
      }));

      setMisureDisponibili(misure);

      //Se tutte le misure non sono disponibili imposto il reparto come escluso
      if (
        misure.filter((m) => m.disabled === true).length ===
        props.reparto.misure.length
      ) {
        setEscluso(true);
        return;
      }
    }

    //VERIFICO L'esclusione generale
    const dal = new Date(props.dataInizio);
    const al = new Date(props.dataInizio);
    al.setDate(al.getDate() + props.numGiorni);
    let isEscluso = false;
    for (let i = 0; i < props.reparto.periodiEsclusi.length; i++) {
      const escDal = new Date(props.reparto.periodiEsclusi[i].dal);
      const escAl = new Date(props.reparto.periodiEsclusi[i].al);

      if (
        dal.setHours(0, 0, 0, 0) <= escAl.setHours(0, 0, 0, 0) &&
        al.setHours(0, 0, 0, 0) >= escDal.setHours(0, 0, 0, 0)
      ) {
        isEscluso = true;
      }
    }
    //debugger;
    setEscluso(isEscluso);
  }, [
    props.dataInizio,
    props.numGiorni,
    props.reparto.misure,
    props.reparto.periodiEsclusi,
    props.reparto.periodiEsclusiMisura,
  ]);

  const picUrl = `https://skinolo.blob.core.windows.net/webimg/${props.codNoleggio}/${props.reparto.picture}`;
  moment.locale("it", itLocale);

  const getListaNumGiorni = () => {
    const arr = [];
    for (let i = 1; i < 15; i++) {
      arr.push({
        key: i,
        text: i,
        value: i,
      });
    }
    return arr;
  };

  const getPrezzo = () => {
    const listino = props.reparto.listinoStandard.find(
      (l) =>
        moment(l.dal) <= moment(props.dataInizio) &&
        moment(l.al) >= moment(props.dataInizio) &&
        l.idFrazioneNoloShop === props.idFrazione
    );
    if (listino === undefined) return 0;
    return listino.prezzo.toFixed(2);
  };

  const getPrezzoApplicato = () => {
    const listino = props.reparto.listinoApplicato.find(
      (l) =>
        moment(l.dal) <= moment(props.dataInizio) &&
        moment(l.al) >= moment(props.dataInizio) &&
        l.idFrazioneNoloShop === props.idFrazione
    );
    if (listino === undefined) {
      //debugger;
      setEscluso(true);
      return 0;
    }
    if (listino.prezzo === 0) {
      //debugger;
      setEscluso(true);
      return 0;
    }

    return listino.prezzo.toFixed(2);
  };

  return (
    <Segment className="reparto">
      <Grid stackable columns="2">
        <Grid.Row className="reparto_riga-descrizione">
          <Grid.Column>
            <h2 className="reparto_titolo">{props.reparto.titolo}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="reparto_riga-descrizione">
          <Grid.Column width={8}>
            <h5 className="reparto_descrizione">{props.reparto.descrizione}</h5>
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="reparto_griglia-dati">
              <div className="reparto_griglia-dati_elemento">
                <div>{props.t("periodo_noleggio")}</div>
                <div className="reparto_griglia-dati_elemento_date">
                  {props.dataInizio.format("DD/MM/YYYY")}{" "}
                  <Icon name="caret right" />
                  {!props.stagionale
                    ? moment(props.dataInizio, "DD-MM-YYYY")
                        .add(
                          props.numGiorni > 0 ? props.numGiorni - 1 : 0,
                          "days"
                        )
                        .format("DD/MM/YYYY")
                    : "STAGIONALE"}
                </div>
              </div>
            </div>
            {!escluso && (
              <div className="reparto_blocco-prezzi">
                <div className="reparto_blocco-prezzi_elemento">
                  {" "}
                  <div style={{ textAlign: "right" }}>
                    {props.t("prezzo_online")}
                  </div>
                  <div
                    style={{ fontSize: "1.71428571428rem", fontWeight: "bold" }}
                  >
                    € {getPrezzoApplicato()}
                  </div>
                </div>
                {getPrezzoApplicato() !== getPrezzo() && (
                  <div className="reparto_blocco-prezzi_elemento">
                    {" "}
                    <div style={{ textAlign: "center" }}>
                      {props.t("prezzo_listino")}
                    </div>
                    <div
                      style={{
                        fontSize: "1.71428571428rem",
                        textDecoration: "line-through",
                      }}
                    >
                      € {getPrezzo()}
                    </div>
                  </div>
                )}
              </div>
            )}
            {escluso && (
              <div className="reparto_blocco-prezzi">
                <h3 style={{ textDecoration: "underline", color: "darkred" }}>
                  {props.t("non_disponibile_periodo")}
                </h3>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="reparto_riga-carrello">
          <Grid.Column
            width={formVisualizzata && !escluso ? 16 : 8}
            textAlign="center"
          >
            <Image
              src={picUrl}
              style={{ margin: "0 auto" }}
              className="reparto_immagine"
            ></Image>
          </Grid.Column>
          {!formVisualizzata && !escluso && (
            <Grid.Column
              verticalAlign="middle"
              textAlign={isMobile ? "center" : "right"}
            >
              <Button
                primary
                onClick={() => {
                  setFormVisualizzata(true);
                }}
              >
                <Icon name="caret down" size="large"></Icon> {props.t("scegli")}
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
        {formVisualizzata && !escluso && (
          <>
            {/* {props.reparto.checkDisponibilita && (
              <Grid.Row centered>
                <Grid.Column width="16" centered>
                  <div style={{ textAlign: "center" }}>
                    <h3>Misura</h3>
                    <Form className="reparto_form-misure">
                      <Form.Group inline>
                        {misureDisponibli.map((m) => (
                          <div>
                            <Form.Radio
                              label={m.misura}
                              disabled={m.disabled}
                              onChange={() =>
                                props.onMisuraCambiata(
                                  props.reparto.idNoloshop,
                                  m.misura
                                )
                              }
                              value={props.misura}
                              checked={props.misura === m.misura}
                            />
                            {m.disabled && (
                              <div>
                                <div>non disponibile</div>
                                <div>per le date</div>
                                <div>scelte</div>
                              </div>
                            )}
                          </div>
                        ))}
                      </Form.Group> 
                    </Form>
                  </div>
                </Grid.Column>
              </Grid.Row>
            )} */}
            <Grid.Row>
              <Grid.Column width="16">
                <FormDettagli
                  onClose={() => {
                    setFormVisualizzata(false);
                  }}
                  t={props.t}
                  datiOrdine={props.datiOrdine}
                  accessori={props.reparto.accessori}
                  misureDisponibli={misureDisponibli}
                  checkDisponibilita={props.reparto.checkDisponibilita}
                  tipoUtilizzatore={props.reparto.tipoUtilizzatore}
                  dataInizio={props.dataInizio}
                  numGiorni={props.numGiorni}
                  idFrazione={props.idFrazione}
                  onChange={(tipo, value) => {
                    props.onDatiUtilizzatoreCambiati(
                      props.reparto.idNoloshop,
                      tipo,
                      value
                    );
                  }}
                  onAggiungiAccessorio={(idAccessorio) =>
                    props.onAggiungiAccessorio(
                      props.reparto.idNoloshop,
                      idAccessorio
                    )
                  }
                  onRimuoviAccessorio={(idAccessorio) =>
                    props.onRimuoviAccessorio(
                      props.reparto.idNoloshop,
                      idAccessorio
                    )
                  }
                  prezzoReparto={getPrezzoApplicato()}
                  onAggiungiRigaCarrello={props.onAggiungiRigaCarrello}
                  codNoleggio={props.codNoleggio}
                ></FormDettagli>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </Segment>
  );
};

Reparto.propTypes = {
  reparto: PropTypes.object.isRequired,
  dataInizio: PropTypes.object.isRequired,
  numGiorni: PropTypes.number.isRequired,
  datiOrdine: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  codNoleggio: PropTypes.string.isRequired,
  onMisuraCambiata: PropTypes.func.isRequired,
  onDatiUtilizzatoreCambiati: PropTypes.func.isRequired,
  onAggiungiAccessorio: PropTypes.func.isRequired,
  onRimuoviAccessorio: PropTypes.func.isRequired,
  onAggiungiRigaCarrello: PropTypes.func.isRequired,
};

export default Reparto;
